import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import style from "../style.module.css";
import trashIcon from "../../../assets/Filing/trashIcon.svg";
import Alert from "../../../components/Alert";

export const StepThree = ({
	updateFilingData = () => {},
	filingData = null
}) => {
	const [reciept, setReciept] = useState(
		filingData?.supportingDocs?.reciept ?? null
	);
	const [supportingDocs, setSupportingDocs] = useState({
		pension: filingData?.supportingDocs?.pension ?? null,
		nhf: filingData?.supportingDocs?.nhf ?? null,
		nhis: filingData?.supportingDocs?.nhis ?? null,
		lifeAssurance: filingData?.supportingDocs?.lifeAssurance ?? null
	});

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const restrictFileSize = (file, field) => {
		if (file) {

			// Check if the file size exceeds 500KB
			const fileSizeInKB = file.size / 1024;

			if (fileSizeInKB > 500) {
				setAlert({
					showing: true,
					type: "error",
					message:
						"File size exceeds the 500KB limit. Please choose a smaller file."
				});

			} else {
				setSupportingDocs((prev) => ({
					...prev,
					[field]: file
				}));
			}
		}
	};

	useEffect(() => {
		updateFilingData &&
			updateFilingData({
				...filingData,
				supportingDocs: {
					...(filingData?.supportingDocs || {}),
					pension: supportingDocs?.pension,
					nhf: supportingDocs?.nhf,
					nhis: supportingDocs?.nhis,
					lifeAssurance: supportingDocs?.lifeAssurance,
					reciept: reciept
				}
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supportingDocs, reciept]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.wrapper}>

				<div className={`bg-warning ${styles.notice__left}`}>
					<p>
						<strong>Instructions</strong>
					</p>
					<ul>
						<li>You have entered values for your annual tax reliefs</li>
						<li>Kindly upload the supporting documents</li>
						<li>Please upload the relevant documents</li>
						<li>If you have any challenge, contact:</li>
						<li>EMAIL: email@email.com</li>
						<li>
							Mrs Amadi (HOD of Assessment Department) - 08037874266;
							Mrs. Ogba Stephanie - 08066830190; Alex - 07032139423; Chidi -
							08162842990
						</li>
					</ul>
				</div>
				<br />

				<div className={`row ${styles.uploads}`}>
					<div className={`col-md-6`}>
						<p>
							<strong>
								Payment Receipt: Annual Pension (Max: 500KB)
							</strong>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="pension"
								accept="image/png, image/jpeg, image/webp"
								// ref={pensionRef}
								onChange={(e) => {
									restrictFileSize(e.target.files[0], 'pension');
								}}
								style={{ display: "none" }}
							/>

							<label
								htmlFor="pension"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								{
									supportingDocs?.pension &&
									<a href={URL?.createObjectURL(supportingDocs?.pension)}
										target="_blank"
										rel="noreferrer"
										style={{ color: 'green' }}
									>
										{supportingDocs?.pension?.name}
									</a>
								}

							</span>
							{supportingDocs?.pension?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setSupportingDocs((prevVal) => ({
												...prevVal,
												pension: null
											}));
											// pensionRef.current.value = null;
										}}
									/>
								</span>
							)}
						</span>
					</div>

					<div className={`col-md-6`}>
						<p>
							<strong>Payment Receipt: Annual NHF (Max: 500KB)</strong>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="nhf"
								accept="image/png, image/jpeg, image/webp"
								// ref={nhfRef}
								onChange={(e) => {
									restrictFileSize(e.target.files[0], 'nhf');
									// setSupportingDocs((prev) => ({
									// 	...prev,
									// 	nhf: e.target.files[0]
									// }));
								}}
								style={{ display: "none" }}
							/>
							<label
								htmlFor="nhf"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								{supportingDocs?.nhf &&
									<a href={URL?.createObjectURL(supportingDocs?.nhf)}
										target="_blank"
										rel="noreferrer"
										style={{ color: 'green' }}
									>
										{supportingDocs?.nhf?.name}
									</a>
								}
							</span>
							{supportingDocs?.nhf?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setSupportingDocs((prevVal) => ({
												...prevVal,
												nhf: null
											}));
											// nhfRef.current.value = null;
										}}
									/>
								</span>
							)}
						</span>
					</div>

					<div className={`col-md-6`}>
						<p>
							<strong>Payment Receipt: Annual NHIS (Max: 500KB)</strong>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="nhis"
								accept="image/png, image/jpeg, image/webp"
								// ref={nhisRef}
								onChange={(e) => {
									restrictFileSize(e.target.files[0], 'nhis');
									// setSupportingDocs((prev) => ({
									// 	...prev,
									// 	nhis: e.target.files[0]
									// }));
								}}
								style={{ display: "none" }}
							/>
							<label
								htmlFor="nhis"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								{supportingDocs?.nhis &&
									<a href={URL?.createObjectURL(supportingDocs?.nhis)}
										target="_blank"
										rel="noreferrer"
										style={{ color: 'green' }}
									>
										{supportingDocs?.nhis?.name}
									</a>
								}
							</span>
							{supportingDocs?.nhis?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setSupportingDocs((prevVal) => ({
												...prevVal,
												nhis: null
											}));
											// nhisRef.current.value = null;
										}}
									/>
								</span>
							)}
						</span>
					</div>

					<div className={`col-md-6`}>
						<p>
							<strong>
								Payment Receipt: Life assurance (Max: 500KB)
							</strong>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="lifeAssurance"
								accept="image/png, image/jpeg, image/webp"
								// ref={lifeAssuranceRef}
								onChange={(e) => {
									restrictFileSize(e.target.files[0], 'lifeAssurance');
									// setSupportingDocs((prev) => ({
									// 	...prev,
									// 	lifeAssurance: e.target.files[0]
									// }));
								}}
								style={{ display: "none" }}
							/>
							<label
								htmlFor="lifeAssurance"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								{supportingDocs?.lifeAssurance &&
									<a href={URL?.createObjectURL(supportingDocs?.lifeAssurance)}
										target="_blank"
										rel="noreferrer"
										style={{ color: 'green' }}
									>
										{supportingDocs?.lifeAssurance?.name}
									</a>
								}
							</span>
							{supportingDocs?.lifeAssurance?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setSupportingDocs((prevVal) => ({
												...prevVal,
												lifeAssurance: null
											}));
											// lifeAssuranceRef.current.value = null;
										}}
									/>
								</span>
							)}
						</span>
					</div>

					<div className={`col-md-6`}>
						<p>
							<strong>Receipt (Max: 500KB)</strong>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="reciept"
								accept="image/png, image/jpeg, image/webp"
								// ref={recieptRef}
								onChange={(e) => setReciept(e.target.files[0])}
								style={{ display: "none" }}
							/>

							<label
								htmlFor="reciept"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								{reciept &&
									<a href={URL?.createObjectURL(reciept)}
										target="_blank"
										rel="noreferrer"
										style={{ color: 'green' }}
									>
										{reciept?.name}
									</a>
								}
							</span>
							{reciept?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setReciept(null);
											// recieptRef.current.value = null;
										}}
									/>
								</span>
							)}
						</span>
					</div>
				</div>
				<br />
			</div>
		</>
	);
};
