import { useState, useEffect, createRef } from "react";
import Table from "../../components/Table";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { formatAmount } from "../../utils";

const MDAPage = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [mdaCode, setMdaCode] = useState("");
	const [revenueCode, setRevenueCode] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);

	const filterRef = createRef();

	const headers = [
		["MDA Code", "mdaCode"],
		["MDA ACRONYM", "mdaShortName"],
		["MDA NAME", "name"],
		["Revenue Code", "revenueCode"],
		["Revenue Source Name", "revenueSourceName"],
		["Amount (NGN)", "amount", formatAmount],
		["Category", "category"],
		["Billing Group", "billingGroup"],
		["Handling System", "handlingSystem"]
	];

	const getMDAs =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault();

			setLoading(true);
			if (!filterRef.current.classList.contains(styles.no__display)) {
				filterRef.current.classList.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=20&`;
			if (mdaCode) {
				query += `mdaCode=${mdaCode}&`;
			}

			if (revenueCode) {
				query += `revenueCode=${revenueCode}&`;
			}

			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}

			if (sortOrder) {
				if (sortOrder === "a-to-z") {
					query += `sortflg=1&`;
				} else {
					query += `sortflg=2&`;
				}
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				const info = await axios
					.get(`/admin/mda?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => (res.data || {}).data);

				if (info) {
					setData(info.data);
					setTotal(info.total);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	const clearFilters = () => {
		setMdaCode("");
		setRevenueCode("");
		setMinAmount("");
		setMaxAmount("");
		setSortOrder("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getMDAs()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getMDAs()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button style={{ visibility: "hidden" }}>
					View Entire Table
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!mdaCode &&
								!revenueCode &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="mda_code">MDA Code</label>
								<input
									name="mda_code"
									id="mda_code"
									type="text"
									value={mdaCode}
									onChange={(e) => setMdaCode(e.target.value)}
									placeholder="MDA Code"
								/>
							</div>
							<div>
								<label htmlFor="revenue_code">
									Revenue Code
								</label>
								<input
									name="revenue_code"
									id="revenue_code"
									type="text"
									value={revenueCode}
									onChange={(e) =>
										setRevenueCode(e.target.value)
									}
									placeholder="Revenue Code"
								/>
							</div>
						</form>
						<p>By Amount Paid</p>
						<form>
							<div>
								<label htmlFor="min_amount">Min Amount</label>
								<input
									name="min_amount"
									id="min_amount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="100,000"
								/>
							</div>
							<div>
								<label htmlFor="max_amount">Max Amount</label>
								<input
									name="max_amount"
									id="max_amount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="2,000,000"
								/>
							</div>
						</form>
						<p>Sort Taxpayer Name Alphabetically</p>
						<form className={styles.radio__form}>
							<div>
								<input
									name={sortOrder}
									id="a_to_z"
									type="radio"
									value="a-to-z"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="a_to_z">A to Z</label>
							</div>
							<div>
								<input
									name={sortOrder}
									id="z_to_a"
									type="radio"
									value="z-to-a"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="z_to_a">Z to A</label>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							type="submit"
							onClick={getMDAs()}
						>
							Search
						</button>
					</div>
				</div>
				<NavLink to="/create-mda">Create MDA</NavLink>
			</div>
			<div className={styles.table__div}>
				<Table
					headers={headers}
					loading={loading}
					data={data}
					full
					pageSize={20}
					total={total}
					onPageChanged={getMDAs}
				/>
			</div>
		</div>
	);
};

export default MDAPage;
