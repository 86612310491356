import { useState, useEffect, createRef } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import Modal from "../../components/Modal/regular";
import approvedCheck from "../../assets/Certificate/approvedCheck.svg";
import failureCheck from "../../assets/Certificate/failureCheck.svg";
import styles from "./style.module.css";
import styles1 from "../../Dashboard/MDAs/style.module.css";
import { formatDate, getStatusText } from "../../utils";

const userInfo = JSON.parse(localStorage.getItem("user_info"));

const headers = [
	["Individuals Name", "Name"],
	["ESBN", "ESBN"],
	["Bill Number", "BillNumber"],
	["Generated By", "Inputter"],
	["Total Tax Due", "AnnualTaxDue"],
	["Total Income", "TaxableIncome"],
	// ["Ttl Prepayments", "revenueResource"],
	["Individual Address", "Address"],
	["Payment Status", "PaymentStatus"],
	["Process Status", "ApproveFlg", getStatusText],
	["Reason for rejection", "RejectReason"],
	["Date Issued", "createdAt", formatDate]
];

const AuthoriseHNIBill = () => {
	const [data, setData] = useState(null);
	const [dataTotal, setDataTotal] = useState(0);
	const [loading, setLoading] = useState(false);

	const [taxZone, setTaxZone] = useState("");
	const [taxOffice, setTaxOffice] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [inputter, setInputter] = useState("");
	const [taxLGA, setTaxLGA] = useState("");
	const [email, setEmail] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [ownersname, setownersname] = useState("");
	const [OwnersESBN, setOwnersESBN] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);

	const [viewModalShowing, setViewModalShowing] = useState(false);
	const [loadingCert, setLoadingCert] = useState(false);
	const [certificate, setCertificate] = useState(null);
	const [reviewedCertDetails, setReviewedCertDetails] = useState(null);
	const [approveModalShowing, setApproveModalShowing] = useState(false);
	const [approving, setApproving] = useState(false);
	const [approvalSuccessModalShowing, setApprovalSuccessModalShowing] =
		useState(false);
	const [rejectModalShowing, setRejectModalShowing] = useState(false);
	const [revokeModalShowing, setRevoketModalShowing] = useState(false);
	const [rejecting, setRejecting] = useState(false);
	const [rejectionSuccessModalShowing, setRejectionSuccessModalShowing] =
		useState(false);

	const [revokeReason, setRevokeReason] = useState("");
	const [revoking, setRevoking] = useState(false);
	const [revocationSuccessModalShowing, setRevocationSuccessModalShowing] =
		useState(false);

	const [rejectReason, setRejectReason] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const filterRef = createRef();

	const getCertificates =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);

			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;
			if (ownersname) {
				query += `Name=${ownersname}&`;
			}

			if (OwnersESBN) {
				query += `ESBN=${OwnersESBN}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (inputter) {
				query += `inputter=${inputter}&`;
			}

			if (taxZone) {
				query += `TaxZone=${taxZone}&`;
			}

			if (taxOffice) {
				query += `taxOffice=${taxOffice}&`;
			}

			if (email) {
				query += `email=${email}&`;
			}

			if (phoneNumber) {
				query += `phone=${phoneNumber}&`;
			}

			if (BillNumber) {
				query += `BillNo=${BillNumber}&`;
			}

			if (taxLGA) {
				query += `TaxLGA=${taxLGA}&`;
			}

			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}
			try {
				let response = await axios
					.get(`allverifyhni?${query}`)
					.then((res) => res.data);
				if (response?.data?.length) setData(response?.data ?? []);
				if (response?.total) setDataTotal(response?.total);
				setLoading(false);
				return response?.data;
			} catch (error) {
				if (error.response) {
					setLoading(false);
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setLoading(false);
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
					setLoading(false);
				}
			}
		};

	const viewCertificate = async (billNumber, viewMode) => {
		setLoadingCert(true);
		setViewModalShowing(true);
		try {
			const data = await axios
				.get(`gethnibill/${billNumber}/${viewMode}`)
				.then((res) => res.data);

			setCertificate(data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingCert(false);
		}
	};

	const approve = async () => {
		setApproving(true);
		setApproveModalShowing(false);

		try {
			await axios
				.post(`processhni`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "3", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked
					modifier: userInfo?.email, // email/login Id of the processor
					recId: reviewedCertDetails?.id,
					reason4Reject: "test"
				})
				.then((res) => res.data);

			setViewModalShowing(false);
			setApprovalSuccessModalShowing(true);
			getCertificates()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setApproving(false);
		}
	};

	const reject = async (e) => {
		e?.preventDefault();
		setRejecting(true);

		try {
			const res = await axios
				.post(`processhni`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "2",
					recId: reviewedCertDetails?.id,
					modifier: userInfo?.email,
					reason4Reject: rejectReason
				})
				.then((res) => res.data);

			setRejectionSuccessModalShowing(false);
			setRejectReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
			getCertificates()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};
	const revoke = async (e) => {
		e?.preventDefault();
		setRevoking(true);

		try {
			const res = await axios
				.post(`processhni`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "4",
					recId: reviewedCertDetails?.id,
					modifier: userInfo?.email,
					reason4Reject: revokeReason
				})
				.then((res) => res.data);

			setRevocationSuccessModalShowing(false);
			setRevokeReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
			getCertificates()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const clearFilters = () => {
		setOwnersESBN("");
		setPhoneNumber("");
		setStartDate("");
		setEndDate("");
		setownersname("");
		setBillNumber("");
		setInputter("");
		setTaxOffice("");
		setTaxLGA("");
		setTaxZone("");
		setMinAmount("");
		setMaxAmount("");

		filterRef.current.classList.toggle(styles1.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getCertificates()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		if (!viewModalShowing) {
			setApproveModalShowing(false);
			setRejectModalShowing(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewModalShowing]);

	useEffect(() => {
		if (!rejectionSuccessModalShowing) setRejectReason("");
	}, [rejectionSuccessModalShowing]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{viewModalShowing && (
				<Modal toggle={setViewModalShowing} showing={viewModalShowing}>
					{loadingCert ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<>
							{getStatusText(reviewedCertDetails.status) ===
								"Pending" && (
								<p className={styles.review__instructions}>
									Please review the form details below. Once
									you have completed your review, select
									either "Approve" or "Decline" to proceed
									with your decision.
								</p>
							)}
							<div
								dangerouslySetInnerHTML={{
									__html: certificate
								}}
							></div>
							{getStatusText(reviewedCertDetails.status) ===
								"Pending Approval" && (
								<div className={styles.review__btns}>
									<button
										className="tertiary__btn"
										onClick={() =>
											setRejectModalShowing(true)
										}
										disabled={approveModalShowing}
									>
										{rejecting ? "Declining..." : "Decline"}
									</button>
									<button
										className="primary__btn"
										onClick={() =>
											setApproveModalShowing(true)
										}
										disabled={rejectModalShowing}
									>
										{approving ? "Approving..." : "Approve"}
									</button>
									{/* Idiot proof modal for approval */}
									{approveModalShowing && (
										<div
											className={`${styles.idiotProof__modal} ${styles.approve__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Approve</span> this HNI
												Bill with{" "}
												<b>
													{
														reviewedCertDetails.billNumber
													}
												</b>{" "}
												Bill Number?
											</p>
											<div
												className={
													styles.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setApproveModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => approve()}
													disabled={approving}
												>
													Yes
												</button>
											</div>
										</div>
									)}
									{/* Idiot proof modal for rejection */}
									{rejectModalShowing && (
										<div
											className={`${styles.idiotProof__modal} ${styles.reject__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Reject</span> this HNI
												Bill with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												bill number?
											</p>
											<div
												className={
													styles.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setRejectModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => {
														setRejectionSuccessModalShowing(
															true
														);
														setRejectModalShowing(
															false
														);
														setViewModalShowing(
															false
														);
													}}
												>
													Yes
												</button>
											</div>
										</div>
									)}
								</div>
							)}
							{getStatusText(reviewedCertDetails.status) ===
								"Approved" && (
								<div className={styles.review__btns}>
									<button
										className="tertiary__btn"
										onClick={() =>
											setRevoketModalShowing(true)
										}
										disabled={
											approveModalShowing ||
											rejectModalShowing
										}
									>
										{revoking ? "Revoking..." : "Revoke"}
									</button>
									{/* Idiot proof modal for revocation */}
									{revokeModalShowing && (
										<div
											className={`${styles.idiotProof__modal} ${styles.reject__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Revoke</span> this HNI
												Bill with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												bill number?
											</p>
											<div
												className={
													styles.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setRevoketModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => {
														setRevocationSuccessModalShowing(
															true
														);
														setRevoketModalShowing(
															false
														);
														setViewModalShowing(
															false
														);
													}}
												>
													Yes
												</button>
											</div>
										</div>
									)}
								</div>
							)}
						</>
					)}
				</Modal>
			)}
			{/* approval success modal */}
			{approvalSuccessModalShowing && (
				<Modal>
					<div className={styles.approvalSuccess__badge}>
						<img
							src={approvedCheck}
							alt="circle with a tick check inside"
						/>
						<span>APPROVED</span>
					</div>
					<p className={styles.approve__success_message}>
						You have successfully approved{" "}
						<b>{reviewedCertDetails?.name}</b>'s HNI Bill, an email
						would be sent to notify them accordingly.
					</p>
					<button
						className={styles.continue__btn}
						onClick={() => {
							setReviewedCertDetails(null);
							setApprovalSuccessModalShowing(false);
						}}
					>
						Continue
					</button>
				</Modal>
			)}
			{/* rejection success modal */}
			{rejectionSuccessModalShowing && (
				<Modal
					showing={rejectionSuccessModalShowing}
					toggle={setRejectionSuccessModalShowing}
				>
					<div className={styles.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={styles.reject__reason_instruction}>
						You are about to reject{" "}
						<b>
							{reviewedCertDetails?.Name ||
								reviewedCertDetails?.name}
						</b>
						's HNI Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => reject(e)}
						className={styles.reject__reason_form}
					>
						<label htmlFor="rejectReason">
							Reason for Rejecting{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="rejectReason"
							placeholder="write the reason for rejecting this HNI Bill"
							value={rejectReason}
							onChange={(e) => setRejectReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!rejectReason || rejecting}
						>
							{rejecting ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			{/* rejection success modal */}
			{revocationSuccessModalShowing && (
				<Modal
					showing={revocationSuccessModalShowing}
					toggle={setRevocationSuccessModalShowing}
				>
					<div className={styles.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={styles.reject__reason_instruction}>
						You are about to revoke{" "}
						<b>
							{reviewedCertDetails?.Name ||
								reviewedCertDetails?.name}
						</b>
						's HNI Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => revoke(e)}
						className={styles.reject__reason_form}
					>
						<label htmlFor="revokeReason">
							Reason for Revocation{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="revokeReason"
							placeholder="write the reason for revoking this HNI Bill"
							value={revokeReason}
							onChange={(e) => setRevokeReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!revokeReason || revoking}
						>
							{revoking ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			<div
				className={styles1.actions__div}
				style={{ display: "flex", justifyContent: "flex-end" }}
			>
				<div className={styles1.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles1.no__display
							);
						}}
						className={styles1.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles1.filter__div,
							styles1.no__display
						].join(" ")}
					>
						<div className={styles1.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles1.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles1.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!OwnersESBN &&
								!startDate &&
								!endDate &&
								!ownersname &&
								!taxLGA &&
								!taxZone &&
								!taxOffice &&
								!email &&
								!BillNumber &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="BillNo">ESBN</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={OwnersESBN}
									onChange={(e) =>
										setOwnersESBN(e.target.value)
									}
									placeholder="Eg. 00909986588"
								/>
							</div>
							<div>
								<label htmlFor="BillNo">Bill Number</label>
								<input
									name="BillNo"
									id="BillNo"
									type="text"
									value={BillNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg. AB20241000000000EN"
								/>
							</div>
							<div>
								<label htmlFor="ownersname">Name</label>
								<input
									name="OwnersName"
									id="ownersname"
									type="text"
									value={ownersname}
									onChange={(e) =>
										setownersname(e.target.value)
									}
									placeholder="Eg. Odogwu Okeke"
								/>
							</div>
							<div>
								<label htmlFor="email">Email</label>
								<input
									name="email"
									id="email"
									type="text"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Eg. test@example.com"
								/>
							</div>
							<div>
								<label htmlFor="phone">Phone Number</label>
								<input
									name="phone"
									id="phone"
									type="text"
									value={phoneNumber}
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="inputter">Inputter</label>
								<input
									name="inputter"
									id="inputter"
									type="text"
									value={inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
								/>
							</div>
						</form>
						<p>By Tax</p>
						<form>
							<div>
								<label htmlFor="taxZone">Tax Zone</label>
								<input
									name="taxZone"
									id="taxZone"
									type="text"
									value={OwnersESBN}
									onChange={(e) => setTaxZone(e.target.value)}
									placeholder="Eg. Enugu"
								/>
							</div>
							<div>
								<label htmlFor="taxOffice">Tax Office</label>
								<input
									name="taxOffice"
									id="taxOffice"
									type="text"
									value={taxOffice}
									onChange={(e) =>
										setTaxOffice(e.target.value)
									}
									placeholder="Eg. Enugu"
								/>
							</div>
							<div>
								<label htmlFor="taxZone">Tax LGA</label>
								<input
									name="taxZone"
									id="taxZone"
									type="text"
									value={taxLGA}
									onChange={(e) => setTaxLGA(e.target.value)}
									placeholder="Eg. Enugu West"
								/>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="minAmount">
									Minimum Ammount
								</label>
								<input
									name="minAmount"
									id="minAmount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="maxAmount">
									Maximum Amount
								</label>
								<input
									name="maxAmount"
									id="maxAmount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles1.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getCertificates()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__wrapper}>
				<Table
					headers={headers}
					data={data || []}
					full
					pageSize={20}
					loading={loading}
					total={dataTotal}
					onRowClick={() => null}
					onPageChanged={getCertificates}
					onView={(data) => {
						viewCertificate(data.BillNumber, 1);
						setReviewedCertDetails({
							billNumber: data.BillNumber,
							id: data.id,
							name: data?.Name,
							status: data.ApproveFlg
						});
					}}
				/>
			</div>
		</section>
	);
};

export default AuthoriseHNIBill;
