import React, { useRef, useState } from "react";
import { StepFive } from "./corporateTabs/stepFive";
import { FilingStep } from "./corporateTabs/filingStep";

export const FileAnnualReturns = () => {
	const [tabState, setTabState] = useState(1);
	// const [filingData, setFilingData] = useState(null);
	const filingRefData = useRef([]);

	const updateFilingRefData = (data) => {
		filingRefData.current = data;
	};

	const tabViews =
		tabState === 5 ? (
			<StepFive
				updateTab={(tabNumber) => setTabState(tabNumber)}
				// updateFilingData={(data) => updateFilingRefData(data)}
				filingData={filingRefData.current}
			/>
		) : (
			<FilingStep
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => updateFilingRefData(data)}
				filingData={filingRefData.current}
			/>
		);

	return (
		<div>
			<div>{tabViews}</div>
		</div>
	);
};
