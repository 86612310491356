import React from "react";
import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { formatAmount, formatDate, getStatusText } from "../../utils";
import Table from "../../components/Table";
import Modal from "../../components/Modal/regular";
import CreateTaxPayer from "./create";
import homeStyle from "./style.module.css";
import hniStyle from "../HNI/style.module.css";
import styles from "../MDAs/style.module.css";
import Alert from "../../components/Alert";
import approvedCheck from "../../assets/Certificate/approvedCheck.svg";
import failureCheck from "../../assets/Certificate/failureCheck.svg";
import MDAstyles from "../../Dashboard/MDAs/style.module.css";
import { exportExcel, remapObj } from "../../utils";

const user = JSON.parse(localStorage.getItem("user_info"));

const headers = [
	["ESBN", "OwnersESBN"],
	["Bill Number", "BillNumber"],
	["Property ID", "PropertyId"],
	["Name", "PayerName"],
	["Current Bill Amt", "CurrentBillAmt", formatAmount],
	["Out. Bill Amt", "OutStandingBillAmt", formatAmount],
	["Total Amt Paid", "TotalAmtPaid", formatAmount],
	["Tax Zone", "TaxZone"],
	["Tax LGA", "TaxLGA"],
	["Tax Office", "TaxOffice"],
	["House No", "HouseNo"],
	["House Street", "HouseStreet"],
	["Created At", "createdAt", formatDate],
	["Payment Status", "PaymentStatus"],
	["Process Status", "ApproveFlg", getStatusText],
	["Inputter", "Inputter"]
];

const AuthoriseBill = () => {
	const [createModal, setCreateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [OwnersESBN, setOwnersESBN] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [ownersname, setownersname] = useState("");
	const [Inputter, setInputter] = useState("");
	const [PropertyId, setPropertyId] = useState("");
	const [houseNo, setHouseNo] = useState("");
	const [houseStreet, setHouseStreet] = useState("");
	const [taxZone, setTaxZone] = useState("");
	const [allTaxZones, setAllTaxZones] = useState([]);
	const [taxLGA, setTaxLGA] = useState("");
	const [allTaxLGAs, setAllTaxLGAs] = useState([]);
	const [taxOffice, setTaxOffice] = useState("");
	const [allTaxOffices, setAllTaxOffices] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState("");
	const [processStatus, setProcessStatus] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const [billsToPrint, setBillsToPrint] = useState([]);
	const [readyToPrintAll, setReadyToPrintAll] = useState(false);
	const [billNumbersToPrint, setBillNumbersToPrint] = useState([]);
	const [indexOfBillToRemove, setIndexOfBillToRemove] = useState(null);
	const [removingBill, setRemovingBill] = useState(false);
	const [isGettingReady, setIsGettingReady] = useState(false);
	const filterRef = createRef();

	// New Approval system start
	const [viewModalShowing, setViewModalShowing] = useState(false);
	const [loadingCert, setLoadingCert] = useState(false);
	const [certificate, setCertificate] = useState(null);
	const [reviewedCertDetails, setReviewedCertDetails] = useState(null);
	const [approveModalShowing, setApproveModalShowing] = useState(false);
	const [approving, setApproving] = useState(false);
	const [approvalSuccessModalShowing, setApprovalSuccessModalShowing] =
		useState(false);
	const [rejectModalShowing, setRejectModalShowing] = useState(false);
	const [revokeModalShowing, setRevoketModalShowing] = useState(false);
	const [rejecting, setRejecting] = useState(false);
	const [rejectionSuccessModalShowing, setRejectionSuccessModalShowing] =
		useState(false);

	const [revokeReason, setRevokeReason] = useState("");
	const [revoking, setRevoking] = useState(false);
	const [revocationSuccessModalShowing, setRevocationSuccessModalShowing] =
		useState(false);

	const [rejectReason, setRejectReason] = useState("");

	const reject = async (e) => {
		e?.preventDefault();
		setRejecting(true);
		try {
			const res = await axios
				.post(`processmda`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "2",
					coyid: user?.mdaModuleCoy,
					recId: reviewedCertDetails?.id,
					reason4Reject: rejectReason
				})
				.then((res) => res.data);

			setRejectionSuccessModalShowing(false);
			setRejectReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const approve = async () => {
		setApproving(true);
		setApproveModalShowing(false);

		try {
			await axios
				.post(`processmda`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "3", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked
					recId: reviewedCertDetails?.id,
					coyid: user?.mdaModuleCoy,
					reason4Reject: "test"
				})
				.then((res) => res.data);

			setViewModalShowing(false);
			setApprovalSuccessModalShowing(true);
			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setApproving(false);
		}
	};

	const handleView = (data) => {
		viewCertificate(data.nb, data?.id, 1);
		setReviewedCertDetails({
			billNumber: data.nb,
			Name: data.Name || data?.OwnersName,
			id: data?.id,
			status: getStatusText(data.ApproveFlg)
		});
	};

	const viewCertificate = async (billNumber, id, viewMode = 1) => {
		if (viewMode === 1) {
			setLoadingCert(true);
			setViewModalShowing(true);
		}

		try {
			const data = await axios
				.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}/1`, {
					responseType: viewMode === 2 ? "blob" : "json"
				})
				.then((res) => res.data);

			if (viewMode === 1) {
				setCertificate(data);
			} else {
				setViewModalShowing(false);
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: data.msg
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.msg ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingCert(false);
		}
	};
	// New Approval system end

	const [billNoOfAmountPaidShowing, setBillNoOfAmountPaidShowing] =
		useState("");
	const [amountPaid, setAmountPaid] = useState("");

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	let templateStr = `
<html>
<head>
    <style>
        /* Style for regular display */

        #print_root {
            width: 100%;
            height: 50%;
            /* Adjust height as needed */
        }

        /* Style for printing */
        @media print {
            @page {
                size: landscape;
            }

            #print_root {
                page-break-after: always;
                // height: 100px;
            }
        }
    </style>
</head>

<body>
        {PRINT_STR}
</body>
</html>
`;

	const getProfile =
		({
			currentPage = 1,
			pageSize = 20,
			download = false,
			printing = false
		} = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);

			if (!filterRef?.current?.classList?.contains(styles.no__display)) {
				filterRef?.current?.classList?.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;
			if (ownersname) {
				query += `Name=${ownersname}&`;
			}

			if (OwnersESBN) {
				query += `ESBN=${OwnersESBN}&`;
			}

			if (BillNumber) {
				query += `BillNo=${BillNumber}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (Inputter) {
				query += `Inputter=${Inputter}&`;
			}

			if (PropertyId) {
				query += `propertyId=${PropertyId}&`;
			}

			if (houseNo) {
				query += `houseNo=${houseNo}&`;
			}

			if (houseStreet) {
				query += `houseStreet=${houseStreet}&`;
			}

			if (taxOffice) {
				query += `taxOffice=${taxOffice}&`;
			}

			if (taxZone) {
				query += `TaxZone=${taxZone}&`;
			}

			if (taxLGA) {
				query += `TaxLGA=${taxLGA}&`;
			}

			if (paymentStatus) {
				query += `paymentStatus=${paymentStatus}&`;
			}

			if (processStatus) {
				query += `processStatus=${processStatus}&`;
			}

			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let data = await axios
					.get(
						`mdareports/mdabillverifierdisplay/${user?.mdaModuleCoy}?${query}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res.data || { data: {} });
				if (!data || !data?.data) {
					// handle errors
					return null;
				}

				if (!download && !printing) setData(data.data);
				setTotal(data?.total);

				return data.data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message ||
							error.response.msg ||
							error.response.data.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setLoading(false);
			}
		};

	const revoke = async (e) => {
		e?.preventDefault();
		setRevoking(true);

		try {
			const res = await axios
				.post(`processmda`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "4",
					recId: reviewedCertDetails?.id,
					coyid: user?.mdaModuleCoy,
					reason4Reject: revokeReason
				})
				.then((res) => res.data);

			setRevocationSuccessModalShowing(false);
			setRevokeReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const getAllTaxOffices = async () => {
		try {
			const offices = await axios
				.get(`settings/assesstaxoffice`)
				.then((res) => res.data);

			setAllTaxOffices(offices.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error?.response?.data?.msg
				});
			}
		}
	};

	const getAllTaxLGAs = async () => {
		try {
			const LGAs = await axios
				.get(`settings/lgass/01`)
				.then((res) => res.data);

			setAllTaxLGAs(LGAs.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error?.response?.data?.msg
				});
			}
		}
	};

	const getAllTaxZones = async () => {
		try {
			const zones = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => res.data);

			setAllTaxZones(zones.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error?.response?.data?.msg
				});
			}
		}
	};

	const getAmountPaid = async (billNumber) => {
		try {
			const amountPaid = await axios
				.get(`account/bill/payment/${billNumber}`)
				.then((res) => res.data.data);

			setAmountPaid(amountPaid);
		} catch (error) {
			setAmountPaid("");
			setBillNoOfAmountPaidShowing("");
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const clearFilters = () => {
		setOwnersESBN("");
		setStartDate("");
		setEndDate("");
		setownersname("");
		setBillNumber("");
		setInputter("");
		setPropertyId("");
		setHouseNo("");
		setHouseStreet("");
		setTaxOffice("");
		setTaxLGA("");
		setTaxZone("");
		setProcessStatus("");
		setPaymentStatus("");
		setMinAmount("");
		setMaxAmount("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getProfile()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getProfile()();
		getAllTaxLGAs();
		getAllTaxZones();
		getAllTaxOffices();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const exportAnalytics = async () => {
		const info = await getProfile({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		exportExcel(remapObj(info, headers), headers, `MDA Report.xlsx`);
	};

	const toggleAddToPrint = async (billNumber) => {
		try {
			const bill = await axios
				.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}/1`)
				.then((res) => res.data || { data: [] });

			setBillNumbersToPrint((billNos) => [...billNos, billNumber]);
			setBillsToPrint((bills) => [...bills, bill]);
			setBillsToPrint((bills) => [...bills, "\n \n \n \n \n \n"]);
		} catch (error) {
			console.error(error);
		}
	};

	const removeFromPrint = (billNumber) => {
		setBillNumbersToPrint((billNos) =>
			billNos.filter((number, i) => {
				if (number === billNumber) setIndexOfBillToRemove(i * 2);
				setRemovingBill(true);
				return number !== billNumber;
			})
		);
	};

	const removeFromBillsToPrint = () => {
		setBillsToPrint((bills) =>
			bills.filter((bill, i) => i !== indexOfBillToRemove)
		);
		setBillsToPrint((bills) =>
			bills.filter((bill, i) => i !== indexOfBillToRemove)
		);
		setRemovingBill(false);
	};

	useEffect(() => {
		if (removingBill) {
			removeFromBillsToPrint();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removingBill]);

	const handleBulkPrinting = (e) => {
		e?.preventDefault();

		if (!!billsToPrint) {
			templateStr = templateStr.replace(
				"{PRINT_STR}",
				billsToPrint.join("\b")
			);
			const w = window.open();
			w.document.write(templateStr);
			w.document.close();
			w.onload = () => {
				w.focus();
				w.print();
			};

			w.onafterprint = () => {
				w.close();
				setBillNumbersToPrint([]);
				setBillsToPrint([]);
				setReadyToPrintAll(false);
			};
		} else {
			return;
		}
	};

	const printAll = async () => {
		setIsGettingReady(true);

		const bills = await getProfile({
			currentPage: 1,
			pageSize: total,
			printing: true
		})();

		await Promise.allSettled(
			bills.map(async (each) => {
				try {
					const bill = await axios
						.get(`mdanotice/${each.nb}/${user?.mdaModuleCoy}/1`)
						.then((res) => res.data || { data: [] });

					setBillsToPrint((bills) => [...bills, bill]);
					setBillsToPrint((bills) => [...bills, "\n \n \n \n \n \n"]);
				} catch (error) {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: `Bill ${each.nb} failed to load: ${
								error.response.data?.msg ||
								error.response.data?.errors?.[0] ||
								error.response.data?.errors?.[0]?.message ||
								error.response.data?.errors?.details[0]
									?.message ||
								error.response.message ||
								error.response.data.message
							}`
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: `Bill ${each.nb} failed to load: ${error.response?.data?.msg}`
						});
					}
				}
			})
		);

		setReadyToPrintAll(true);
		setIsGettingReady(false);
	};

	useEffect(() => {
		if (readyToPrintAll) {
			handleBulkPrinting();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyToPrintAll]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{viewModalShowing && (
				<Modal toggle={setViewModalShowing} showing={viewModalShowing}>
					{loadingCert ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<>
							{reviewedCertDetails.status ===
								"Pending Approval" && (
								<p className={styles.review__instructions}>
									Please review the form details below. Once
									you have completed your review, select
									either "Approve" or "Decline" to proceed
									with your decision.
								</p>
							)}
							<div
								dangerouslySetInnerHTML={{
									__html: certificate
								}}
							></div>
							{reviewedCertDetails.status ===
								"Pending Approval" && (
								<div className={hniStyle.review__btns}>
									<button
										className="tertiary__btn"
										onClick={() =>
											setRejectModalShowing(true)
										}
										disabled={approveModalShowing}
									>
										{rejecting ? "Declining..." : "Decline"}
									</button>
									<button
										className="primary__btn"
										onClick={() =>
											setApproveModalShowing(true)
										}
										disabled={rejectModalShowing}
									>
										{approving ? "Approving..." : "Approve"}
									</button>
									{/* Idiot proof modal for approval */}
									{approveModalShowing && (
										<div
											className={`${hniStyle.idiotProof__modal} ${hniStyle.approve__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Approve</span> this Bill
												with{" "}
												<b>
													{
														reviewedCertDetails.billNumber
													}
												</b>{" "}
												Bill Number?
											</p>
											<div
												className={
													hniStyle.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setApproveModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => approve()}
													disabled={approving}
												>
													Yes
												</button>
											</div>
										</div>
									)}
									{/* Idiot proof modal for rejection */}
									{rejectModalShowing && (
										<div
											className={`${hniStyle.idiotProof__modal} ${hniStyle.reject__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Reject</span> this Bill
												with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												bill number?
											</p>
											<div
												className={
													hniStyle.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setRejectModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => {
														setRejectionSuccessModalShowing(
															true
														);
														setRejectModalShowing(
															false
														);
														setViewModalShowing(
															false
														);
													}}
												>
													Yes
												</button>
											</div>
										</div>
									)}
								</div>
							)}

							{reviewedCertDetails.status === "Approved" && (
								<div className={hniStyle.review__btns}>
									<button
										className="btn btn-danger"
										style={{
											padding: "12px",
											height: "fit-content"
										}}
										onClick={(e) => {
											e.preventDefault();
											setViewModalShowing(false);
										}}
									>
										{"Close"}
									</button>
									<button
										className="tertiary__btn"
										onClick={() =>
											setRevoketModalShowing(true)
										}
										disabled={
											approveModalShowing ||
											rejectModalShowing
										}
									>
										{revoking ? "Revoking..." : "Revoke"}
									</button>
									{/* Idiot proof modal for revocation */}
									{revokeModalShowing && (
										<div
											className={`${hniStyle.idiotProof__modal} ${hniStyle.reject__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Revoke</span> this Bill
												with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												bill number?
											</p>
											<div
												className={
													hniStyle.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setRevoketModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => {
														setRevocationSuccessModalShowing(
															true
														);
														setRevoketModalShowing(
															false
														);
														setViewModalShowing(
															false
														);
													}}
												>
													Yes
												</button>
											</div>
										</div>
									)}
								</div>
							)}
						</>
					)}
				</Modal>
			)}
			{/* approval success modal */}
			{approvalSuccessModalShowing && (
				<Modal>
					<div className={hniStyle.approvalSuccess__badge}>
						<img
							src={approvedCheck}
							alt="circle with a tick check inside"
						/>
						<span>APPROVED</span>
					</div>
					<p className={hniStyle.approve__success_message}>
						You have successfully approved{" "}
						<b>{reviewedCertDetails?.name}</b>'s Bill, an email
						would be sent to notify them accordingly.
					</p>
					<button
						className={hniStyle.continue__btn}
						onClick={() => {
							setReviewedCertDetails(null);
							setApprovalSuccessModalShowing(false);
						}}
					>
						Continue
					</button>
				</Modal>
			)}
			{/* rejection success modal */}
			{rejectionSuccessModalShowing && (
				<Modal
					showing={rejectionSuccessModalShowing}
					toggle={setRejectionSuccessModalShowing}
				>
					<div className={hniStyle.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={hniStyle.reject__reason_instruction}>
						You are about to reject{" "}
						<b>
							{reviewedCertDetails?.Name ||
								reviewedCertDetails?.name}
						</b>
						's Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => reject(e)}
						className={hniStyle.reject__reason_form}
					>
						<label htmlFor="rejectReason">
							Reason for Rejecting{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="rejectReason"
							placeholder="write the reason for rejecting this Bill"
							value={rejectReason}
							onChange={(e) => setRejectReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!rejectReason || rejecting}
						>
							{rejecting ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			{/* rejection success modal */}
			{revocationSuccessModalShowing && (
				<Modal
					showing={revocationSuccessModalShowing}
					toggle={setRevocationSuccessModalShowing}
				>
					<div className={hniStyle.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={hniStyle.reject__reason_instruction}>
						You are about to revoke{" "}
						<b>
							{reviewedCertDetails?.Name ||
								reviewedCertDetails?.name}
						</b>
						's Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => revoke(e)}
						className={hniStyle.reject__reason_form}
					>
						<label htmlFor="revokeReason">
							Reason for Revocation{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="revokeReason"
							placeholder="write the reason for revoking this Bill"
							value={revokeReason}
							onChange={(e) => setRevokeReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!revokeReason || revoking}
						>
							{revoking ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			<div className={styles.actions__div}>
				<button
					className={[
						"secondary__btn",
						MDAstyles.download__button
					].join(" ")}
					onClick={() => exportAnalytics()}
					// disabled={loading}
				>
					Download Report
				</button>

				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!OwnersESBN &&
								!startDate &&
								!endDate &&
								!ownersname &&
								// !OwnerEmail &&
								// !OwnersPhone &&
								!BillNumber &&
								!Inputter &&
								!PropertyId &&
								!houseNo &&
								!houseStreet &&
								!taxOffice &&
								!taxZone &&
								!taxLGA &&
								!processStatus &&
								!paymentStatus &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="owneresbn">ESBN</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={OwnersESBN}
									onChange={(e) =>
										setOwnersESBN(e.target.value)
									}
									placeholder="Eg. 00909986588"
								/>
							</div>
							<div>
								<label htmlFor="billNumber">Bill Number</label>
								<input
									name="billNumber"
									id="billNumber"
									type="text"
									value={BillNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg. AB2024155662EN"
								/>
							</div>
						</form>
						<p>By Owner Details</p>
						<form>
							<div>
								<label htmlFor="ownersname">Owner Name</label>
								<input
									name="OwnersName"
									id="ownersname"
									type="text"
									value={ownersname}
									onChange={(e) =>
										setownersname(e.target.value)
									}
									placeholder="Eg. Odogwu Okeke"
								/>
							</div>
							{/* <div>
								<label htmlFor="OwnerEmail">Owner Email</label>
								<input
									name="OwnerEmail"
									id="OwnerEmail"
									type="email"
									value={OwnerEmail}
									onChange={(e) =>
										setOwnerEmail(e.target.value)
									}
									placeholder="Eg. odogwu@okeke.com"
								/>
							</div>
							<div>
								<label htmlFor="OwnersPhone">Owner Phone</label>
								<input
									name="OwnersPhone"
									id="OwnersPhone"
									type="tel"
									value={OwnersPhone}
									onChange={(e) =>
										setOwnersPhone(e.target.value)
									}
									placeholder="Eg. 09333348848"
								/>
							</div> */}
							<div>
								<label htmlFor="PropertyId">Property ID</label>
								<input
									name="PropertyId"
									id="PropertyId"
									type="text"
									value={PropertyId}
									onChange={(e) =>
										setPropertyId(e.target.value)
									}
									placeholder="Eg. 4567"
								/>
							</div>
							<div>
								<label htmlFor="houseNo">House Number</label>
								<input
									name="houseNo"
									id="houseNo"
									type="text"
									value={houseNo}
									onChange={(e) => setHouseNo(e.target.value)}
									placeholder="Eg. No. 34 Odimegwu"
								/>
							</div>
							<div>
								<label htmlFor="houseStreet">
									House Street
								</label>
								<input
									name="houseStreet"
									id="houseStreet"
									type="text"
									value={houseStreet}
									onChange={(e) =>
										setHouseStreet(e.target.value)
									}
									placeholder="Eg. Uduma Street"
								/>
							</div>
							<div>
								<label htmlFor="taxLGA">Tax LGA</label>
								<select
									name="taxLGA"
									id="taxLGA"
									value={taxLGA}
									onChange={(e) => setTaxLGA(e.target.value)}
								>
									<option value="">--select tax LGA--</option>
									{allTaxLGAs?.map((LGA, i) => (
										<option key={i}>
											{LGA.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxZone">Tax Zone</label>
								<select
									name="taxZone"
									id="taxZone"
									value={taxZone}
									onChange={(e) => setTaxZone(e.target.value)}
								>
									<option value="">
										--select tax zone--
									</option>
									{allTaxZones?.map((zone, i) => (
										<option key={i}>
											{zone.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxOffice">Tax Office</label>
								<select
									name="taxOffice"
									id="taxOffice"
									value={taxOffice}
									onChange={(e) =>
										setTaxOffice(e.target.value)
									}
								>
									<option value="">
										--select tax office--
									</option>
									{allTaxOffices?.map((office, i) => (
										<option key={i}>
											{office.Description}
										</option>
									))}
								</select>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="minAmount">Min Amount</label>
								<input
									name="minAmount"
									id="minAmount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="₦ 100, 000"
								/>
							</div>
							<div>
								<label htmlFor="maxAmount">Max Amount</label>
								<input
									name="maxAmount"
									id="maxAmount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="₦ 1, 000, 000"
								/>
							</div>
						</form>
						<p>By Status</p>
						<form>
							<div>
								<label htmlFor="processStatus">
									Process Status
								</label>
								<select
									name="processStatus"
									id="processStatus"
									value={processStatus}
									onChange={(e) =>
										setProcessStatus(e.target.value)
									}
								>
									<option value="">
										--select process status--
									</option>
									<option>Approved</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
							<div>
								<label htmlFor="paymentStatus">
									Payment Status
								</label>
								<select
									name="paymentStatus"
									id="paymentStatus"
									value={paymentStatus}
									onChange={(e) =>
										setPaymentStatus(e.target.value)
									}
								>
									<option value="">
										--select payment status--
									</option>
									<option>Paid</option>
									<option>Partly Paid</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
						</form>
						<p>By Inputter</p>
						<form>
							<div>
								<label htmlFor="Inputter">Inputter</label>
								<input
									name="Inputter"
									id="Inputter"
									type="email"
									value={Inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
									placeholder="Eg. ada@example.com"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getProfile()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			{data && (
				<p>
					<span style={{ color: "red" }}>*</span> Click each row to
					show amount paid from total bill amount.
				</p>
			)}
			<div className={homeStyle.printBtns}>
				<button
					className={[
						"secondary__btn",
						MDAstyles.download__button
					].join(" ")}
					onClick={handleBulkPrinting}
				>
					Print Selected {billNumbersToPrint.length}
				</button>
				<button
					className={["primary__btn", homeStyle.printAll__Btn].join(
						" "
					)}
					style={{
						height: "47px"
					}}
					onClick={printAll}
					disabled={
						!OwnersESBN &&
						!BillNumber &&
						!ownersname &&
						!PropertyId &&
						!houseNo &&
						!houseStreet &&
						!taxLGA &&
						!taxZone &&
						!taxOffice &&
						!minAmount &&
						!maxAmount &&
						!processStatus &&
						!paymentStatus &&
						!Inputter &&
						!startDate &&
						!endDate
					}
				>
					{isGettingReady
						? "Getting Ready..."
						: `Print All ${
								(OwnersESBN ||
									BillNumber ||
									ownersname ||
									PropertyId ||
									houseNo ||
									houseStreet ||
									taxLGA ||
									taxZone ||
									taxOffice ||
									minAmount ||
									maxAmount ||
									processStatus ||
									paymentStatus ||
									Inputter ||
									startDate ||
									endDate) &&
								total
							}`}
				</button>
			</div>
			<div className={styles.table__div}>
				<Table
					headers={headers}
					data={data}
					full
					pageSize={20}
					loading={loading}
					total={total}
					onPageChanged={getProfile}
					onRowClick={(data) => {
						setAmountPaid("");
						setBillNoOfAmountPaidShowing(data.nb);
						getAmountPaid(data.nb);
					}}
					billNoOfAmountPaidShowing={billNoOfAmountPaidShowing}
					setBillNoOfAmountPaidShowing={setBillNoOfAmountPaidShowing}
					amountPaid={amountPaid}
					onView={(data) => {
						Number(data?.ApproveFlg) !== 6
							? handleView(data)
							: setAlert({
									showing: true,
									type: "error",
									message: `${getStatusText(
										data.ApproveFlg
									)} Bill cannot be viewed.`
								});
					}}
					printArray={billNumbersToPrint}
					onAddToPrint={(data) => {
						data?.ApproveFlg === "3"
							? toggleAddToPrint(data.nb)
							: setAlert({
									showing: true,
									type: "error",
									message: `${getStatusText(
										data.ApproveFlg
									)} Bill cannot be added to print.`
								});
					}}
					onRemoveFromPrint={(data) => removeFromPrint(data.nb)}
				/>
			</div>
			{createModal && (
				<Modal small showing={createModal} toggle={setCreateModal}>
					<CreateTaxPayer />
				</Modal>
			)}
		</section>
	);
};

export default AuthoriseBill;
