import { React, useState, useEffect, createRef } from "react";
import axios from "axios";
import Table from "../components/Table";
import Alert from "../../src/components/Alert";
import styles1 from "../../src/Dashboard/MDAs/style.module.css";
import { useHistory } from "react-router";
import styles from "../Dashboard/MDAs/style.module.css";
import constants from "../constants";
import { exportExcel, remapObj } from "../utils";

axios.defaults.base1URL = constants.BASE1_URL;

const Authoriser = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState();
	const [customername, setCusname] = useState("");
	const [recieptNumber, setReciept] = useState("");
	const [billNumber, setBillno] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [total, setTotal] = useState(0);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const filterRef = createRef();
	const headers = [
		["ESBN", "esbn"],
		["Customer Name", "customerName"],
		["Bank Branch", "branchName"],
		["Bill Number", "billNumber"],
		["Total Amount", "totalAmountPaid"],
		["Revenue", "revenue"],
		["Reciept Number", "receiptNumber"],
		["Payment Date", "paymentDate"],
		["Status", "approveRejectFlag", getStatusText]
	];

	function getStatusText(approveRejectFlag) {
		if (approveRejectFlag === 0) {
			return "Pending";
		} else if (approveRejectFlag === 1) {
			return "Approved";
		} else {
			return "Denied";
		}
	}

	const _getAllManualRecon = ({ currentPage = 1, pageSize = 20 }) => {
		let query = `page=${currentPage}&limit=${pageSize}&`;
		if (customername) {
			query += `Name=${customername}&`;
		}
		if (recieptNumber) {
			query += `ReceiptNumber=${recieptNumber}&`;
		}
		if (billNumber) {
			query += `BillNumber=${billNumber}&`;
		}

		return axios
			.get(
				`${constants.BASE1_URL}/manualRecon/getAllManualReconRecords?${query}`
			)
			.then((res) => (res.data || {}).data);
	};

	useEffect(() => {
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	const clearFilters = () => {
		setBillno("");
		setCusname("");
		setReciept("");
		setStartDate("");
		setEndDate("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	const exportAnalytics = async () => {
		const info = await _getAllManualRecon({
			currentPage: 1,
			pageSize: total
		});
		if (!info.data) return;
		exportExcel(
			remapObj(info.data, headers),
			headers,
			`MAKER_CHECKER Report.xlsx`
		);
	};

	const getAllManualRecon =
		({ currentPage = 1, pageSize = 20 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			try {
				const info = await _getAllManualRecon({
					currentPage,
					pageSize
				});

				if (info) {
					setData(info.data);
					setTotal(info.totalItems);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getAllManualRecon()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAllManualRecon()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", styles.download__button].join(
						" "
					)}
					onClick={() => exportAnalytics()}
				>
					Download Report
				</button>

				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!customername &&
								!recieptNumber &&
								!startDate &&
								!endDate &&
								!billNumber
							}
						>
							Clear Filter fields
						</button>
						<p>By Reciept Number</p>
						<form>
							<div>
								<label htmlFor="reciept">Reciept</label>
								<input
									name="reciept"
									id="reciept"
									type="text"
									value={recieptNumber}
									onChange={(e) => setReciept(e.target.value)}
								/>
							</div>
						</form>
						<p>By Customer Name</p>
						<form>
							<div>
								<label htmlFor="staffName">Customer Name</label>
								<input
									name="staffName"
									id="staffName"
									type="text"
									value={customername}
									onChange={(e) => setCusname(e.target.value)}
									placeholder="Eg Odogwu Okeke"
								/>
							</div>
							<div>
								<label htmlFor="billNumber">Bill Number</label>
								<input
									name="billNumber"
									id="billNumber"
									type="text"
									value={billNumber}
									onChange={(e) => setBillno(e.target.value)}
									placeholder="Enter Bill Number"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getAllManualRecon}
						>
							Search
						</button>
					</div>
				</div>
			</div>

			<div className={styles1.table__div}>
				<Table
					headers={headers}
					loading={loading}
					data={data}
					onPageChanged={getAllManualRecon}
					full
					pageSize={20}
					total={total}
					onView={(data) => {
						history.push(`/maker-checker/view/${data.id}`);
					}}
				/>
			</div>
		</div>
	);
};

export default Authoriser;
