import { useState, useEffect } from "react";
import axios from "axios";
import { formatAmount, formatDate } from "../../utils";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import styles from "./style.module.css";

const PaymentHistory = () => {
	const [billNumber, setBillNumber] = useState("");
	const [fetching, setFetching] = useState(false);
	const [history, setHistory] = useState([]);
	const [totalPaid, setTotalPaid] = useState(null);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["S/N", "SN"],
		["Bill Number", "billno"],
		["Amount Paid", "Amount", (amount) => `₦ ${formatAmount(amount)}`],
		["Payment Date", "PaymentDate", formatDate]
	];

	async function getPaymentHistory() {
		setFetching(true);
		try {
			const response = await axios.get(`/account/billv2/${billNumber}`, {
				headers: { secureddata: "VGhpc2lzTm9ybGljcw==" }
			});

			setHistory(
				response.data.data.paymentDetails.map((item, index) => ({
					...item,
					SN: index + 1
				}))
			);
			setTotalPaid(response.data.data.totPayment);
			setBillNumber("");
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	}

	useEffect(() => {
		if (billNumber?.length >= 17) getPaymentHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billNumber]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				onSubmit={(e) => {
					e.preventDefault();
					getPaymentHistory();
				}}
				className={styles.form}
			>
				<label htmlFor="billNo">
					Bill Number <span className="required">*</span>
				</label>
				<input
					type="text"
					name="billNo"
					id="billNo"
					value={billNumber}
					onChange={(e) => setBillNumber(e.target.value)}
					placeholder="Enter Bill Number"
				/>
				<button
					type="submit"
					className="primary__btn"
					onClick={getPaymentHistory}
					disabled={fetching || !billNumber}
				>
					{fetching ? "Fetching..." : "Get Payment History"}
				</button>
			</form>
			<Table data={history} headers={headers} loading={fetching} />
			{totalPaid && !fetching && (
				<p className={styles.totalPaid}>
					Total Paid: <span>₦ {formatAmount(totalPaid)}</span>
				</p>
			)}
		</section>
	);
};

export default PaymentHistory;
