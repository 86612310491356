import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { formatDate, getStatusText, formatAmount } from "../../../utils";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import filterStyles from "../../MDAs/style.module.css";

const CouponAuthorisation = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [status, setStatus] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [generatedBy, setGeneratedBy] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const filterRef = createRef();

	const headers = [
		["Global Coupon ID", "couponglobalid"],
		[
			"Total Coupon Amount",
			"totalcouponamt",
			(amount) => `₦ ${formatAmount(amount)}`
		],
		["Total Batch Generated", "totaltag"],
		["Total Sold", "totalTagsPurchased"],
		["Generated By", "inputterid"],
		["Status", "approveflg", getStatusText],
		["Date Issued", "createdate", formatDate]
	];

	const getCoupons =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (
				!filterRef.current?.classList.contains(filterStyles.no__display)
			) {
				filterRef.current?.classList.toggle(filterStyles.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			if (status) {
				query += `procstatus=${status}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}

			if (generatedBy) {
				query += `inputterid=${generatedBy}&`;
			}

			try {
				let { items, pagination } = await axios
					.get(`/getglobalkoupons4authoriser?${query}`, {
						headers: {
							secureddata: "VGhpc2lzTm9ybGljcw=="
						}
					})
					.then((res) => res.data.data);
				setData(items);
				setTotal(pagination.totalItems);

				return items;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getCoupons()();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearFilters = () => {
		setStatus("");
		setMinAmount("");
		setMaxAmount("");
		setStartDate("");
		setEndDate("");
		setGeneratedBy("");

		filterRef.current.classList.toggle(filterStyles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getCoupons()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={filterStyles.actions__div}>
				<button
					className="primary__btn"
					style={{ padding: "0 16px", visibility: "hidden" }}
				>
					Generate Automated Certificate
				</button>
				<div className={filterStyles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								filterStyles.no__display
							);
						}}
						className={filterStyles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							filterStyles.filter__div,
							filterStyles.no__display
						].join(" ")}
					>
						<div className={filterStyles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										filterStyles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={filterStyles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!status &&
								!startDate &&
								!endDate &&
								!generatedBy &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="status">Status</label>
								<select
									name="status"
									id="status"
									value={status}
									onChange={(e) => setStatus(e.target.value)}
								>
									<option value="">
										Select Process Status
									</option>
									<option value={0}>Pending</option>
									<option value={5}>Pending Approval</option>
									<option value={3}>Approved</option>
									<option value={2}>Declined</option>
									<option value={1}>Cancelled</option>
									<option value={4}>Revoked</option>
									<option value={6}>Deleted</option>
								</select>
							</div>
						</form>
						<p>By Serial Number Amount</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="minAmount">Min Amount</label>
								<input
									name="minAmount"
									id="minAmount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="Enter minimum amount"
								/>
							</div>
							<div>
								<label htmlFor="maxAmount">Max Amount</label>
								<input
									name="maxAmount"
									id="maxAmount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="Enter maximum amount"
								/>
							</div>
						</form>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="generatedBy">
									Generated By
								</label>
								<input
									name="generatedBy"
									id="generatedBy"
									type="email"
									value={generatedBy}
									onChange={(e) =>
										setGeneratedBy(e.target.value)
									}
									placeholder="Enter email of generator"
								/>
							</div>
						</form>
						<p>By Issue Date</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								filterStyles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getCoupons()}
							disabled={
								!status &&
								!minAmount &&
								!maxAmount &&
								!generatedBy &&
								!startDate &&
								!endDate
							}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<Table
				headers={headers}
				loading={loading}
				data={data}
				full
				pageSize={20}
				total={total}
				onPageChanged={getCoupons}
				onView={(data) => {
					history.push("/coupon/authorisation/view", data);
				}}
			/>
		</section>
	);
};

export default CouponAuthorisation;
