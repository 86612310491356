import { useState, useEffect } from "react";
import RegularModal from "../Modal/regular";
import failureCheck from "../../assets/Certificate/failureCheck.svg";
import approvedCheck from "../../assets/Certificate/approvedCheck.svg";
import styles from "./style.module.css";

/**
 * @param {Object} props - Props object that is immediately destructured
 * @param {number} props.type - determines buttons shown. 1 = cancel, convert approveFlg to Number and pass for other use cases. 0 by default
 * @param {number} props.alignment - determines the flex justify-content. 1 = left, 2 = center, 3 = right. 3 by default
 * @param {string} props.positiveIdiotProofQuestion - the "are you sure you want to..." part of the modal but for approval or request for approval
 * @param {string} props.positiveActionComment - the state controlling the Approval/Request for approval comment input field. When omitted, the widget will not show the comment input field modal, and will execute the approval/request for approval func at the click of the approvalIdiotProofModal "yes" button.
 * @param {Function} props.setPositiveActionComment - the setState controlling the Approval/Request for approval comment input field
 * @param {Function} props.positiveFunction - function that calls the API for approval or request for approval
 * @param {Boolean} props.processingPositive - state that tracks when the API call for approval or request for approval has been called but hasn't returned a response yet
 * @param {Boolean} props.processingNegative - state that tracks when the API call for revoking or cancelling or deleting has been called but hasn't returned a response
 * @param {string} props.negativeIdiotProofQuestion - the "are you sure you want to..." part of the modal but for revoking, cancelling or deleting.
 * @param {string} props.negativeReasonMessage - the text telling them what they're about to do before the reason for ... input field
 * @param {string} props.negativeReason - the state controlling the "reason for" input field
 * @param {Function} props.setNegativeReason - the setState controlling the "reason for" input field
 * @param {Function} props.negativeFunction - the function that makes the API call for the negative process.
 */
const ApproveRejectWidget = ({
	type = 0,
	alignment = 3,
	positiveIdiotProofQuestion,
	positiveActionComment,
	setPositiveActionComment,
	positiveFunction,
	processingPositive,
	processingNegative,
	negativeIdiotProofQuestion,
	negativeReasonMessage,
	negativeReason,
	setNegativeReason,
	negativeFunction
}) => {
	const [negativeIdiotProofModalShowing, setNegativeIdiotProofModalShowing] =
		useState(false);
	const [approveIdiotProofModalShowing, setApproveIdiotProofModalShowing] =
		useState(false);
	const [negativeReasonModalShowing, setNegativeReasonModalShowing] =
		useState(false);
	const [
		positiveActionCommentModalShowing,
		setPositiveActionCommentModalShowing
	] = useState(false);

	useEffect(() => {
		if (negativeReason) setNegativeReason("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [negativeReasonModalShowing]);

	return (
		<>
			<div
				className={styles.review__btns}
				style={{
					justifyContent:
						alignment === 1
							? "left"
							: alignment === 2
								? "center"
								: "right"
				}}
			>
				<button
					className="tertiary__btn"
					onClick={() => setNegativeIdiotProofModalShowing(true)}
					disabled={
						approveIdiotProofModalShowing || processingNegative
					}
				>
					{type === 0 || type === 5
						? "Reject"
						: type === 3
							? "Revoke"
							: type === 1
								? "Cancel"
								: "Delete"}
				</button>
				{(type === 0 || type === 5) && (
					<button
						className="primary__btn"
						onClick={() => setApproveIdiotProofModalShowing(true)}
						disabled={
							negativeIdiotProofModalShowing || processingPositive
						}
					>
						{type === 5 &&
						processingPositive &&
						positiveActionComment === undefined
							? "Approving..."
							: type === 5
								? "Approve"
								: type === 0 && processingPositive
									? "Requesting..."
									: "Request for Approval"}
					</button>
				)}
				{/* Idiot proof modal for approval */}
				{approveIdiotProofModalShowing && !processingPositive && (
					<div
						className={`${styles.idiotProof__modal} ${styles.approve__modal}`}
					>
						<p>
							Are you sure you want to{" "}
							<span>
								{type === 5
									? "Approve"
									: "Request for Approval"}
							</span>{" "}
							{positiveIdiotProofQuestion}?
						</p>
						<div className={styles.idiotProof__btns}>
							<button
								onClick={() => {
									setApproveIdiotProofModalShowing(false);
								}}
							>
								No
							</button>
							<button
								onClick={() => {
									setApproveIdiotProofModalShowing(false);
									if (positiveActionComment === undefined) {
										positiveFunction();
									} else {
										setPositiveActionCommentModalShowing(
											true
										);
									}
								}}
								disabled={processingPositive}
							>
								Yes
							</button>
						</div>
					</div>
				)}
				{/* Idiot proof modal for rejection */}
				{negativeIdiotProofModalShowing && !processingNegative && (
					<div
						className={`${styles.idiotProof__modal} ${styles.reject__modal}`}
					>
						<p>
							Are you sure you want to{" "}
							<span>
								{type === 0 || type === 5
									? "Reject"
									: type === 3
										? "Revoke"
										: type === 1
											? "Cancel"
											: "Delete"}
							</span>{" "}
							{negativeIdiotProofQuestion}?
						</p>
						<div className={styles.idiotProof__btns}>
							<button
								onClick={() => {
									setNegativeIdiotProofModalShowing(false);
								}}
							>
								No
							</button>
							<button
								onClick={() => {
									setNegativeReasonModalShowing(true);
									setNegativeIdiotProofModalShowing(false);
								}}
							>
								Yes
							</button>
						</div>
					</div>
				)}
			</div>
			{/* Positive action comment modal */}
			{positiveActionCommentModalShowing && (
				<RegularModal
					showing={positiveActionCommentModalShowing}
					toggle={setPositiveActionCommentModalShowing}
				>
					<div className={styles.approval__badge}>
						<img
							src={approvedCheck}
							alt="circle with a checkmark inside"
						/>
						<span>
							{type === 5 ? "Approval" : "Request for Approval"}
						</span>
					</div>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							positiveFunction();
						}}
						className={styles.approval__comment_form}
					>
						<label htmlFor="positiveActionComment">
							Comment{" "}
							<span style={{ color: "#9e9e9e" }}>(Optional)</span>
						</label>
						<textarea
							id="positiveActionComment"
							placeholder="write your comment here"
							value={positiveActionComment}
							onChange={(e) =>
								setPositiveActionComment(e.target.value)
							}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={processingPositive}
						>
							{processingPositive ? "Continuing..." : "Continue"}
						</button>
					</form>
				</RegularModal>
			)}
			{negativeReasonModalShowing && (
				<RegularModal
					showing={negativeReasonModalShowing}
					toggle={setNegativeReasonModalShowing}
				>
					<div className={styles.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>
							{type === 0 || type === 5
								? "Rejection"
								: type === 3
									? "Revocation"
									: type === 1
										? "Cancellation"
										: "Deletion"}
						</span>
					</div>
					<p className={styles.reject__reason_instruction}>
						{negativeReasonMessage}
					</p>
					<form
						onSubmit={(e) => negativeFunction(e, negativeReason)}
						className={styles.reject__reason_form}
					>
						<label htmlFor="negativeReason">
							Reason for{" "}
							{type === 0 || type === 5
								? "rejecting"
								: type === 3
									? "revoking"
									: type === 1
										? "cancelling"
										: "deleting"}{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="negativeReason"
							placeholder={`write your ${
								type === 0 || type === 5
									? "rejection"
									: type === 3
										? "revocation"
										: type === 1
											? "cancellation"
											: "deletion"
							} reason`}
							value={negativeReason}
							onChange={(e) => setNegativeReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!negativeReason || processingNegative}
						>
							{processingNegative ? "Continuing..." : "Continue"}
						</button>
					</form>
				</RegularModal>
			)}
		</>
	);
};

export default ApproveRejectWidget;
