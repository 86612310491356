import React, { useMemo, useState } from "react";
import styles from "./style.module.css";
import style from "../style.module.css";
import { StepOne } from "./stepOne";
import { StepTwo } from "./stepTwo";
import { StepThree } from "./stepThree";

export const FilingStep = ({
	updateTab = () => {},
	updateFilingData = () => {},
	filingData = null
}) => {
	const [localFilingData, setLocalFilingData] = useState(filingData);

	const buttonState = useMemo(() => {
		if (
			localFilingData?.employees?.length
			// && localFilingData?.recieptData?.data?.length
		) {
			return false;
		} else {
			return true;
		}
	}, [localFilingData]);

	return (
		<div className={styles.wrapper}>
			<StepOne
				updateFilingData={setLocalFilingData}
				filingData={localFilingData}
			/>
			<StepTwo
				updateFilingData={setLocalFilingData}
				filingData={localFilingData}
			/>
			<StepThree
				updateFilingData={setLocalFilingData}
				filingData={localFilingData}
			/>
			<br />
			<br />
			<footer className={style.flex__right}>
				<button
					className={`btn btn-success ${styles.downloads__button}`}
					disabled={buttonState}
					onClick={() => {
						updateFilingData(localFilingData);
						updateTab(5);
					}}
				>
					<p>File Returns</p>
				</button>
			</footer>
			<br />
		</div>
	);
};
