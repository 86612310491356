import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styles from "./style.module.css";
import style from "../style.module.css";
import downloadIcon from "../../../assets/downloadFilingIcon.svg";
import template from "../../../assets/Annual-Filing-Template.xlsx";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import trashIcon from "../../../assets/Filing/trashIcon.svg";
import { formatAmount } from "../../../utils";

const userData = JSON.parse(localStorage.getItem("user_info"));

export const StepOne = ({ updateFilingData = () => {}, filingData }) => {
	const [loading, setLoading] = useState(false);
	const [employees, setEmployees] = useState(filingData?.employees ?? []);
	const [employeesSheet, setEmployeesSheet] = useState(null);
	const [anssid, setAnssid] = useState({});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	let { state } = useLocation();
	const employeesSheetRef = useRef(null);
	const headers = [
		["Employee Name", "fullName"],
		["Employee ESBN", "eSBN"],
		["Months in Service", "monthsInService"],
		["Total Gross Salary (₦)", "annualGrossSalary", formatAmount],
		["Total Deductions (₦)", "annualCalcTotalRelief", formatAmount],
		["Annual Tax Due (₦)", "annualCalcTaxDue", formatAmount]
	];

	const getAnssid = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(
					`users/anssid?anssid=${
						!userData?.anssid ? state?.esbn : userData?.anssid
					}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}
			setAnssid(data?.data);
		} catch (error) {}
	};

	const uploadEmployeeSheet = async () => {
		if (employeesSheet) {
			const formData = new FormData();
			formData.append(
				"file",
				employeesSheet,
				"Annual Filing Template.xlsx"
			);
			formData.append("coyid", state?.coyID || userData?.id);
			formData.append(
				"filingMode",
				state?.filingMode ? state?.filingMode : null
			);
			if (state?.yearOfReturns) {
				formData.append("filingYear", state?.yearOfReturns);
			}
			if (state?.monthOfReturns) {
				formData.append("filingTypeMth", state?.monthOfReturns);
			} else {
				formData.append("filingTypeMth", "");
			}
			if (state?.startMonthOfReturns) {
				formData.append(
					"filingPeriodStartDate",
					state?.startMonthOfReturns
				);
			}
			if (state?.endMonthOfReturns) {
				formData.append(
					"filingPeriodEndDate",
					state?.endMonthOfReturns
				);
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let data = await axios
					.post(`company/annual-returns/staff`, formData, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data || { data: {} });

				if (!data || !data?.data) {
					// handle errors
					return null;
				}
				setEmployees(data?.data?.employees);
				updateFilingData({
					...state,
					...filingData,
					...data?.data,
					address: anssid?.stateOfficeAddress
				});
				setAlert({
					showing: true,
					type: "success",
					message:
						data?.description?.description.toUpperCase() ??
						"Upload successful!"
				});
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message ||
						error.response.data.message ||
						error.response.data ||
						"Upload Unsuccessful!"
				});
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		getAnssid();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.wrapper}>
				<div className={`bg-warning ${styles.notice__left}`}>
					<ul>
						<li>
							This form allows you download the employee Excel/CSV
							Template
						</li>
						<li>
							Fill in the details of your employee enrollments and
							upload the Excel/CSV to start the process of your
							Annual returns.
						</li>
						<li>
							Note that a maximum of 2,000 records are allowed per
							Excel/CSV batch.
						</li>
						<li>
							Make sure your internet connection is fast and
							reliable to avoid disruption.
						</li>

						<li>
							REMEMBER TO FILL ALL COLUMNS THAT ARE COMPULSORY OR
							THE UPLOADS FAILS.
						</li>

						<li>Email info@irs.en.gov.ng if you have an issue.</li>
					</ul>
				</div>
				<br />

				<div className={`row ${style.h1__deets__step_one}`}>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Company Name{" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.name ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								ESBN<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.anssid ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Address<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.stateOfficeAddress ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Contact Email Address{" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.email ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Contact Phone
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{anssid?.phoneNumber ??
								anssid?.phonenumber ??
								"N/A"}
						</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Filing Year
								<span style={{ color: "red" }}> *</span>
							</strong>
						</p>
						<p>{state?.yearOfReturns || "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Type of filing
								<span style={{ color: "red" }}> *</span>
							</strong>
						</p>
						<p>
							{state?.typeOfFiling
								? `By ${state?.typeOfFiling}`
								: "N/A"}
						</p>
					</div>
					{state?.monthOfReturns && (
						<>
							<div className={`col-md-4`}>
								<p>
									<strong>
										Month of Returns
										<span style={{ color: "red" }}> *</span>
									</strong>
								</p>
								<p>{state?.monthOfReturns || "N/A"}</p>
							</div>
						</>
					)}
					{state?.startMonthOfReturns && (
						<>
							<div className={`col-md-4`}>
								<p>
									<strong>
										Start Month of Returns
										<span style={{ color: "red" }}> *</span>
									</strong>
								</p>
								<p>{state?.startMonthOfReturns || "N/A"}</p>
							</div>
						</>
					)}
					{state?.endMonthOfReturns && (
						<>
							<div className={`col-md-4`}>
								<p>
									<strong>
										End Month of Returns
										<span style={{ color: "red" }}> *</span>
									</strong>
								</p>
								<p>{state?.endMonthOfReturns || "N/A"}</p>
							</div>
						</>
					)}
					<div className={`col-md-4`}>
						<p>
							<strong>
								Sector<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.economicSector ?? "N/A"}</p>
					</div>
				</div>
				<br />

				<div className={`row ${styles.uploads}`}>
					<div className={`col-md-6`}>
						<p>
							<strong>
								Download excel template to upload employees
							</strong>
						</p>
						<a
							href={template}
							className={`btn btn-success ${styles.downloads__button}`}
							download
						>
							<img src={downloadIcon} alt="download" />
							<p>Download Template</p>
						</a>
					</div>

					<div className={`col-md-6`}>
						<p>
							<strong>
								Upload Excel Sheet (Use Template Provided)
							</strong>{" "}
							<span style={{ color: "red" }}>*</span>
						</p>
						<span className={style.flex__left}>
							<input
								type="file"
								id="file2-btn"
								style={{ display: "none" }}
								ref={employeesSheetRef}
								accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
								onChange={(e) =>
									setEmployeesSheet(e.target.files[0])
								}
							/>
							<label
								htmlFor="file2-btn"
								className={`text-success`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								<p>{employeesSheet?.name}</p>
							</span>
							<button
								className={`btn btn-success ${styles.downloads__button} ${styles.ml_1}`}
								onClick={() => {
									uploadEmployeeSheet();
									setLoading(true);
								}}
								disabled={!employeesSheet?.name || loading}
							>
								{loading ? "Submitting..." : "Submit"}
							</button>
							{employeesSheet?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setEmployees([]);
											updateFilingData({
												...filingData,
												employees: []
											});
											setEmployeesSheet(null);
											employeesSheetRef.current.value =
												null;
										}}
									/>
								</span>
							)}
						</span>
					</div>
				</div>
				<br />
				<div className={`bg-info ${styles.info}`}>
					<p>
						PLEASE KINDLY REQUEST ALL YOUR STAFF TO OBTAIN THEIR
						ESBN FROM{" "}
						<a href="https://irs.en.gov.ng/individual-enrollment/self-enrollment/new">
							https://irs.en.gov.ng/individual-enrollment/self-enrollment/new
						</a>
						<br />
						BASIC TRANSPORT, RENT are compulsory to fill in the
						template. <br />
						NOTE THAT SUPPORTING DOCUMENTS WOULD BE REQUIRED IF
						ANNUAL NHIS, NHF & PENSION HAVE VALUES
					</p>
				</div>
				<br />
				<br />
				<h5 className={styles.entries__title}>
					Employee entries preview
				</h5>
				<br />

				<Table
					headers={headers}
					data={employees}
					full
					pageSize={20}
					total={employees?.length}
				/>
				<br />
			</div>
		</>
	);
};
