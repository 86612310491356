import { useHistory } from "react-router";
import goBackIcon from "../../assets/blackArrowLeft.svg";
import styles from "./style.module.css";

/**
 * @param {object} props - Props object that is immediately destructured
 * @param {string} props.link - Route string for routing to the corresponding screen
 * @param {Function} props.action - Custom routing action.  E.g., Routing between tabs.
 */

const GoBackButton = ({ link, action }) => {
	const history = useHistory();

	return (
		<button
			className={styles.back__btn}
			onClick={() =>
				link
					? history.push(`${link}`)
					: action
						? action()
						: history.goBack()
			}
		>
			<img
				src={goBackIcon}
				alt="back arrow icon"
				style={{ marginRight: "10px" }}
			/>
			Go Back
		</button>
	);
};

export default GoBackButton;
