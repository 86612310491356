import React, { createRef, useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import Header from "../components/header";
import Sidenav from "../components/sidenav";
import Home from "./Home";
import MDA from "./MDAs";
import IndividualANSSID from "./StateTIN/individual";
import CorporateANSSID from "./StateTIN/corporate";
import ANSSIDAudit from "./StateTIN/audit";
import AuthorizeANSID from "./StateTIN/authorize";
import IndividualAuditDetails from "./StateTIN/individualAuditDetails";
import CorporateAuditDetails from "./StateTIN/corporateAuditDetails";
import RATs from "./RATs";
import JBTs from "./JBTs";
import TotalCollections from "./Total-Collections";
import ClearedBills from "./Bills/cleared";
import UnclearedBills from "./Bills/uncleared";
import CreateMDA from "./MDAs/create";
import CreateJTB from "./JBTs/create";
import User from "./user";
import Claim from "./Claim";
import Role from "./roles";
import AdminChangePassword from "./Admin/admin.change.password";
import CreateUser from "./user/create";
import CreateClaim from "./Claim/create";
import CreateRole from "./roles/create";
import Analytics from "./analysis";
import LGAAnalytics from "./analysis/lga";
import MDAAnalytics from "./analysis/mda";
import RevenueHeadAnalytics from "./analysis/revenuehead";
import HandlingSystemAnalytics from "./analysis/handlingsystem";
import BillingGroupAnalytics from "./analysis/billinggroup";
import MDAOptionsAnalytics from "./analysis/mda.options";
import Summary from "./summary";
import ANSSIDAgent from "./ANSSIDAgent";
import DeactivatedAgents from "./DeactivatedAgent";
import UserDetails from "./UserDetails";
import CompanyDetails from "./CompanyDetails";
import UserSidenav from "../components/sidenav/user";
import Support from "./Support";

import CreateDirectAssessment from "../Direct-assessment/Create-direct-assessment";
import Authorise from "../Direct-assessment/Authorize";
import DirectAssessmentSettings from "../Direct-assessment/Settings";
import AssessmentRecords from "../Direct-assessment/Assessment-records";
import Match from "../Direct-assessment/Match";

import GenerateDAManualEntryCertificate from "./Certificate/generate.manual.da.cert";
import DAManualEntryCertificate from "./Certificate/da.manual.entry";
import AutoDACertificate from "./Certificate/auto.da.certificate";
import ManualDACertificate from "./Certificate/manual.da.certificate";
import GenerateAutoDACertificate from "./Certificate/generate.auto.da.cert";
import AuthoriseDACertificates from "./Certificate/authorise.da";

// Coupon
import CouponGenerationTable from "./Coupon/Generation";
import GenerateCoupon from "./Coupon/Generation/generate";
import CouponGenerationView from "./Coupon/Generation/view";
import CouponAuthorisation from "./Coupon/Authorisation";
import CouponAuthView from "./Coupon/Authorisation/view";
import CouponReview from "./Coupon/Review";
import CouponAuditView from "./Coupon/Review/view";
import CouponPurchase from "./Coupon/Purchase/purchase";
import PurchasedCouponsRecord from "./Coupon/Purchase";
import CouponValidation from "./Coupon/Validation";
import ToDownloadCouponRecords from "./Coupon/Download";
import DownloadCoupon from "./Coupon/Download/view";

// import PAYE from "./PAYE/Create-PAYE-assessment";

import Receipt from "./Receipt";
import ReceiptPymtRef from "./ReceiptPymtRef/receiptref";
// import ReceiptPymtOurs from "./ReceiptPymtRef/receiptours";
import ReceiptPymtSwitch from "./ReceiptPymtRef/receiptswitch";
import Mdareceipt from "./ReceiptPymtRef/mda.receipt";
import ReceiptPymtInv from "./ReceiptPymtRef/receiptinv";
import PaymentHistory from "./Receipt/payment.history";
// import Receipts from "./ReceiptPymtRef/receipts";
import SingleInputReceipt from "./ReceiptPymtRef/single.input.receipt";
import IDDetails from "./Settings/id.details";
import ANSSIDSetup from "./Settings/stateTIN.setup";
import MonthlySalaryRange from "./Settings/monthly.salary.range";
import RevenueDetails from "./Settings/revenue.details";
import ChangePassword from "./ChangePassword";

import SpecialDashboard from "./SpecialDashboard";

import TotalCollectionsIcon from "../assets/Header/totalCollections.svg";
import BillsIcon from "../assets/Header/bills.svg";
import MDAIcon from "../assets/Header/mdas.svg";
import ANSSIDIcon from "../assets/Header/anssid.svg";
import AnalyticsIcon from "../assets/Header/analytics.svg";
import DashboardIcon from "../assets/Header/dashboard.svg";
import UserManagementIcon from "../assets/Header/userManagement.svg";
import ReceiptIcon from "../assets/Header/receipts.svg";
import SettingsIcon from "../assets/Header/settings.svg";
import EmailPhoneChangeIcon from "../assets/Header/emailPhoneChange.svg";
import ChangePasswordIcon from "../assets/Header/changePassword.svg";
import AnnualReturnsIcon from "../assets/Header/annualReturns.svg";
import CouponIcon from "../assets/Header/coupon.svg";

import styles from "./style.module.css";
import Preparebill from "./Multi-company/preparebill";
import MultiCompanySettings from "./Multi-company/settings";
import Profile from "./Multi-company/profile";
import CreateMulticompany from "./Multi-company/create";
// import CreateBill from "./Multi-company/createbill";
import ViewBill from "./Multi-company/billActions/viewBill";
import EditBill from "./Multi-company/billActions/editBill";
import ViewBill4NewHaven from "./Multi-company/billActions4NewHaven/viewBill";
import EditBill4NewHaven from "./Multi-company/billActions4NewHaven/editBill";
// import CreateWaterBill from "./Multi-company/createWaterBill";
import NewHavenShops from "./Multi-company/newHavenCreate";
import CreateNewHavenShops from "./Multi-company/newHavenBillCreate";
import PayeRecords from "./PAYE/Paye-Record";
import CorporatePayeRecord from "./PAYE/Paye-Record/corporatePayeRecord";
import CreatePAYEAssessment from "./PAYE/Create-PAYE-assessment";
import RecordsView from "./PAYE/Paye-Record/recordsView";
import CorporateProfiling from "./StateTIN/corporate.profiling";
import Authorisation from "./PAYE/Authorisation";
import Authoriser from "../Maker-checker/authoriser";
import Inputter from "../Maker-checker/inputter";
import Addnew from "../Maker-checker/addnew";
import { AnnualReturnsSearch } from "./AnnualReturns";
// import { PayeeCompliance } from "./AnnualReturns/payeeCompliance";
// import { WhtCompliance } from "./AnnualReturns/whtCompliance";
import { EmployeeCompliance } from "./AnnualReturns/employee";
// import { ViewFiling } from "./AnnualReturns/viewFiling";
import { ViewEmployees } from "./AnnualReturns/viewEmployees";
import { EditEmployeeDetails } from "./AnnualReturns/editEmployeeDetails";
import Consolidated from "./DemandNotice/Consolidation/consolidated";
import GenerateConsolidated from "./DemandNotice/Consolidation/generate-consolidated";
import ConsolidatedView from "./DemandNotice/Consolidation/consolidatedView";
import { AnnualReturnsCorporate } from "./AnnualReturns/annualReturnsCorporate";
import { FileAnnualReturns } from "./AnnualReturns/fileAnnualReturns";
import GenReceipt from "../Maker-checker/genreceipt";
// import WaterBill from "./Multi-company/WaterBill";
import Water from "./Multi-company/water";
import { AdminFileAnnualReturn } from "./AnnualReturns/adminFileAnnualReturn";
import Newhavenshops from "./Multi-company/newhavenshops";
import Createnewhaven from "./Multi-company/createnewhaven";
import MeteredBill from "./Multi-company/meteredBill";
import EditMaker from "../Maker-checker/edit";
import ViewMaker from "../Maker-checker/view";
import HNISetup from "./HNI/hniSetup";
import HNIHome from "./HNI/home";
import HNITax from "./HNI/hniTax";
import { EditHNI } from "./HNI/editHNI";
import { ViewHNI } from "./HNI/viewHNI";
import HNISetupHome from "./HNI/hniSetupHome";
import { EditSetup } from "./HNI/editSetup";
import AuthoriseHNIBill from "./HNI/authorise";
import { CoporateFilingPeriod } from "./AnnualReturns/coporateFilingPeriod";
import ReducingBalanceReceipt from "../ReducingBalance/reducingBalanceR";
import { CoporateAnnualReturnsTable } from "./AnnualReturns/annualFilingCorporateTable";
import { CoporateViewFiling } from "./AnnualReturns/corporateViewFiling";
import AuthoriseBill from "./Multi-company/authoriseBill";
import AuthoriseNewHaven from "./Multi-company/authorise.newHaven";
import { EFilingReviewer } from "./AnnualReturns/paye/reviewer";
import PrepareOrbaMarketbill from "./Multi-company/prepareOrbaMarketbill";
import OrbaMeteredBill from "./Multi-company/orbaMarket.meteredBill";
import PAYE from "./PAYE/PAYE-Assessment";
import PAYEAssessment from "./PAYE/PAYE-Assessment/assessment";
import AuthView from "./PAYE/Authorisation/authView";
import CorporateIndex from "./PAYE/PAYE-Assessment/corporateIndex";
import CorporateView from "./PAYE/Paye-Record/corporateView";
import ReviewerView from "./PAYE/Reviewer/reviewerView";
import Reviewer from "./PAYE/Reviewer";

import EditOrbaMarketBill from "./Multi-company/billActions/orbaMarket.editBill";
import AuthoriseOrbaMarketBill from "./Multi-company/authoriseOrbaMarket.meteredBill";
import { FileWHTAnnualReturns } from "./AnnualReturns/wht/fileWHTAnnualReturns";
import { CoporateWHTAnnualReturnsTable } from "./AnnualReturns/wht/WHTAnnualFilingCorporateTable";
import { WHTAdminFileAnnualReturn } from "./AnnualReturns/wht/whtAdminFileAnnualReturn";
import { WHTViewFiling } from "./AnnualReturns/wht/WHTViewFiling";
import { WHTFilingReviewer } from "./AnnualReturns/wht/WHTReviewer";
import { WHTAuthoriser } from "./AnnualReturns/wht/WHTAuthoriser";
// import { CoporateAnnualReturnsTable } from "./AnnualReturns/annualFilingCorporateTable";
// import { CoporateViewFiling } from "./AnnualReturns/corporateViewFiling";

// import ViewProfile from "./Multi-company/profileActions/viewProfile";
// import EditProfile from "./Multi-company/profileActions/editProfile";

import ChangeEmailAndPhone from "./EmailPhoneChange/Change";
import EmailPhoneChangeRecord from "./EmailPhoneChange/Records";
import EmailPhoneChangeAuthRequests from "./EmailPhoneChange/Authorisation";
import EmailAndPhoneChangeAuthView from "./EmailPhoneChange/Authorisation/view";
import EmailPhoneChangeReviewRequests from "./EmailPhoneChange/Review";
import EmailAndPhoneChangeReviewView from "./EmailPhoneChange/Review/view";
const coy_ANSSID = process.env.REACT_APP_ANSSID;

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}

const Dashboard = () => {
	const [sidebarOpen, toggleSidebar] = useState(window.innerWidth > 768);
	const { pathname } = useLocation();
	const { path } = useRouteMatch();

	const navRef = createRef();
	const menuRef = createRef();

	const isUser = /^(individual)|(corporate)$/i.test(userInfo?.account_type);
	const isCorporate = /^corporate$/i.test(userInfo?.account_type);

	const isGovernor = userInfo?.roles?.[0]?.Claims?.find((cl) =>
		/governor/i.test(cl.Name)
	);

	const allowedRoutes = (userInfo?.roles?.[0]?.Claims || []).reduce(
		(acc, cl) => {
			acc[cl.Route] = true;
			return acc;
		},
		{}
	);

	const hasDashboard = Boolean(
		Object.keys(allowedRoutes).find((key) => key === "/")
	);

	const routes = {
		[`${path}`]: {
			title: isUser ? "Home" : "Dashboard"
		},
		[`${path}details`]: {
			title: "Details",
			component: isCorporate ? CompanyDetails : UserDetails
		},
		[`${path}password`]: {
			title: "Change Password",
			component: ChangePassword
		},
		[`${path}support`]: {
			title: "Contact Support",
			component: Support
		},
		[`${path}PAYE-records`]: {
			title: "My Paye Record",
			component: CorporatePayeRecord
		},
		[`${path}hni/tax`]: {
			title: `HNI Tax`,
			component: HNIHome
		},
		[`${path}hni/prepare-tax`]: {
			title: `Create HNI Tax`,
			component: HNITax
		},
		[`${path}hni/edit-setup`]: {
			title: `Edit Setup`,
			component: EditSetup
		},
		[`${path}PAYE-filing-corporate`]: {
			title: "PAYE Filing",
			component: AnnualReturnsCorporate
		},
		[`${path}paye-filing/corporate-view`]: {
			title: "PAYE Filing View",
			icon: AnnualReturnsIcon,
			component: CoporateViewFiling
		},
		[`${path}paye-file-returns`]: {
			title: "E-Filing",
			component: FileAnnualReturns
		},
		[`${path}file-annual-returns-period`]: {
			title: "PAYE Filing",
			component: CoporateFilingPeriod
		},
		//
		[`${path}WHT-filing-corporate`]: {
			title: "WHT Filing",
			component: AnnualReturnsCorporate
		},
		[`${path}wht-filing/corporate-view`]: {
			title: "WHT Filing View",
			icon: AnnualReturnsIcon,
			component: CoporateViewFiling
		},
		[`${path}wht-file-returns`]: {
			title: "WHT Filing",
			component: FileAnnualReturns
		},
		[`${path}file-wht-annual-returns-period`]: {
			title: "WHT Filing",
			component: CoporateFilingPeriod
		},
		//
		[`${path}PAYE/PayeSchedule`]: {
			title: "PAYE Schedule",
			component: CreatePAYEAssessment
		},
		[`${path}Paye-Review`]: {
			title: "Paye Details",
			component: RecordsView
		},
		[`${path}PAYE/PAYE-Assessment`]: {
			title: "PAYE-Assessment",
			component: CorporateIndex
		},
		[`${path}Assessment`]: {
			title: "PAYE-Assessment Continuation",
			component: PAYEAssessment
		},
		[`${path}Paye/Review`]: {
			title: "Paye Details",
			component: CorporateView
		}
	};

	const adminRoutes = {
		[`${path}`]: {
			title: "Dashboard",
			icon: DashboardIcon
		},
		[`${path}total-collections`]: {
			title: "Total Collections",
			component: TotalCollections,
			icon: TotalCollectionsIcon
		},
		[`${path}bills/cleared-bills`]: {
			title: "Cleared Bills",
			icon: BillsIcon,
			component: ClearedBills
		},
		[`${path}bills/uncleared-bills`]: {
			title: "Uncleared Bills",
			icon: BillsIcon,
			component: UnclearedBills
		},
		[`${path}mdas`]: {
			title: "MDA",
			icon: MDAIcon,
			component: MDA
		},

		// PAYE Filing Admin navs
		[`${path}paye-filing/file-returns`]: {
			title: "PAYE Filing",
			icon: AnnualReturnsIcon,
			component: FileAnnualReturns
		},
		[`${path}paye-filing/admin-filing`]: {
			title: "PAYE Filing",
			icon: AnnualReturnsIcon,
			component: AdminFileAnnualReturn
		},
		[`${path}paye-filing/admin-filing-table`]: {
			title: "PAYE Filing Process",
			icon: AnnualReturnsIcon,
			component: CoporateAnnualReturnsTable
		},
		[`${path}paye-filing/review`]: {
			title: "Review PAYE Filing",
			icon: AnnualReturnsIcon,
			component: EFilingReviewer
		},
		[`${path}paye-filing/search`]: {
			title: "Authorise PAYE Filing",
			icon: AnnualReturnsIcon,
			component: AnnualReturnsSearch
		},
		[`${path}paye-filing/view`]: {
			title: "E-Filing View",
			icon: AnnualReturnsIcon,
			component: CoporateViewFiling
		},
		[`${path}paye-filing/corporate-view`]: {
			title: "PAYE Filing View",
			icon: AnnualReturnsIcon,
			component: CoporateViewFiling
		},
		// PAYE Filing Admin navs

		// WHT Filing Admin navs
		[`${path}wht-filing/file-returns`]: {
			title: "WHT Filing",
			icon: AnnualReturnsIcon,
			component: FileWHTAnnualReturns
		},
		[`${path}wht-filing/admin-filing`]: {
			title: "WHT Filing",
			icon: AnnualReturnsIcon,
			component: WHTAdminFileAnnualReturn
		},
		[`${path}wht-filing/admin-filing-table`]: {
			title: "WHT Filing Process",
			icon: AnnualReturnsIcon,
			component: CoporateWHTAnnualReturnsTable
		},
		[`${path}wht-filing/review`]: {
			title: "Review WHT Filing",
			icon: AnnualReturnsIcon,
			component: WHTFilingReviewer
		},
		[`${path}wht-filing/search`]: {
			title: "Authorise WHT Filing",
			icon: AnnualReturnsIcon,
			component: WHTAuthoriser
		},
		[`${path}wht-filing/view`]: {
			title: "WHT Filing View",
			icon: AnnualReturnsIcon,
			component: WHTViewFiling
		},
		[`${path}wht-filing/corporate-view`]: {
			title: "WHT Filing View",
			icon: AnnualReturnsIcon,
			component: WHTViewFiling
		},
		// WHT Filing Admin navs

		[`${path}paye-filing/employee`]: {
			title: "PAYE Filing Employee",
			icon: AnnualReturnsIcon,
			component: EmployeeCompliance
		},
		[`${path}paye-filing/view-employees`]: {
			title: "PAYE Filing Employee",
			icon: AnnualReturnsIcon,
			component: ViewEmployees
		},
		[`${path}paye-filing/employee-details`]: {
			title: "PAYE Filing Employee Details",
			icon: AnnualReturnsIcon,
			component: EditEmployeeDetails
		},

		// [`${path}paye-filing/wht-compliance`]: {
		// 	title: "WHT Compliance",
		// 	icon: AnnualReturnsIcon,
		// 	component: WhtCompliance
		// },
		// [`${path}paye-filing/paye-compliance`]: {
		// 	title: "PAYE Compliance",
		// 	icon: AnnualReturnsIcon,
		// 	component: PayeeCompliance
		// },
		[`${path}PAYE/create-PAYE-assessment`]: {
			title: "Create PAYE Assessment",
			icon: MDAIcon,
			component: PAYE
		},
		[`${path}create-mda`]: {
			title: "Create MDA",
			icon: MDAIcon,
			component: CreateMDA
		},
		[`${path}statetin/individual`]: {
			title: `${coy_ANSSID} - Individual`,
			icon: ANSSIDIcon,
			component: IndividualANSSID
		},
		[`${path}statetin/corporate`]: {
			title: `${coy_ANSSID} - Organization`,
			icon: ANSSIDIcon,
			component: CorporateANSSID
		},
		[`${path}statetin/audit`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: ANSSIDAudit
		},
		[`${path}statetin/authorize`]: {
			title: `${coy_ANSSID} - Authorize`,
			icon: ANSSIDIcon,
			component: AuthorizeANSID
		},
		[`${path}audit-statetin/individual/`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: IndividualAuditDetails
		},
		[`${path}audit-statetin/corporate/`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: CorporateAuditDetails
		},
		[`${path}certificate/authorise/da`]: {
			title: `Authorise Certificates`,
			icon: ReceiptIcon,
			component: AuthoriseDACertificates
		},
		[`${path}certificate/da/generate`]: {
			title: `Generate Automated DA Certificate`,
			icon: ReceiptIcon,
			component: GenerateAutoDACertificate
		},
		[`${path}certificate/da`]: {
			title: `Direct Assessment Automated Certificate`,
			icon: ReceiptIcon,
			component: AutoDACertificate
		},
		[`${path}certificate/da-manual-entry-list`]: {
			title: `Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: ManualDACertificate
		},
		[`${path}certificate/da-manual-entry`]: {
			title: `Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: DAManualEntryCertificate
		},
		[`${path}certificate/generate-da-manual-entry-certificate`]: {
			title: `Generate Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: GenerateDAManualEntryCertificate
		},
		[`${path}coupon/generation/view`]: {
			title: `View Generated Coupon`,
			icon: CouponIcon,
			component: CouponGenerationView
		},
		[`${path}coupon/generation`]: {
			title: `Coupon Generation Table`,
			icon: CouponIcon,
			component: CouponGenerationTable
		},
		[`${path}coupon/generate`]: {
			title: `Generate Coupon`,
			icon: CouponIcon,
			component: GenerateCoupon
		},
		[`${path}coupon/authorisation/view`]: {
			title: `Coupon Details`,
			icon: CouponIcon,
			component: CouponAuthView
		},
		[`${path}coupon/authorisation`]: {
			title: `Coupon Authorisation`,
			icon: CouponIcon,
			component: CouponAuthorisation
		},
		[`${path}coupon/review/view`]: {
			title: `Coupon Details`,
			icon: CouponIcon,
			component: CouponAuditView
		},
		[`${path}coupon/review`]: {
			title: `Coupon Review`,
			icon: CouponIcon,
			component: CouponReview
		},
		[`${path}coupon/purchase/records`]: {
			title: `Coupon Purchased Records`,
			icon: CouponIcon,
			component: PurchasedCouponsRecord
		},
		[`${path}coupon/purchase`]: {
			title: `Coupon Purchase`,
			icon: CouponIcon,
			component: CouponPurchase
		},
		[`${path}coupon/validation`]: {
			title: `Coupon Validation`,
			icon: CouponIcon,
			component: CouponValidation
		},
		[`${path}coupon/downloadable-coupons`]: {
			title: `Downloadable Coupon Table`,
			icon: CouponIcon,
			component: ToDownloadCouponRecords
		},
		[`${path}coupon/download`]: {
			title: `Download Coupons`,
			icon: CouponIcon,
			component: DownloadCoupon
		},
		[`${path}rats`]: {
			title: "RAT",
			component: RATs
		},
		[`${path}jtbs`]: {
			title: "JTB",
			component: JBTs
		},
		[`${path}analytics`]: {
			title: "Analytics",
			icon: AnalyticsIcon,
			component: Analytics
		},
		[`${path}lgaanalytics`]: {
			title: "LGA Analytics",
			icon: AnalyticsIcon,
			component: LGAAnalytics
		},
		[`${path}mdaanalytics`]: {
			title: "MDA Analytics",
			icon: AnalyticsIcon,
			component: MDAAnalytics
		},
		[`${path}revenueheadanalytics`]: {
			title: "Revenue Head Analytics",
			icon: AnalyticsIcon,
			component: RevenueHeadAnalytics
		},
		[`${path}handlingsystemanalytics`]: {
			title: "Handling System Analytics",
			icon: AnalyticsIcon,
			component: HandlingSystemAnalytics
		},
		[`${path}billgroupanalytics`]: {
			title: "Billing Group Analytics",
			icon: AnalyticsIcon,
			component: BillingGroupAnalytics
		},
		[`${path}mdaoptionsanalytics`]: {
			title: "Top & Bottom MDAs",
			icon: AnalyticsIcon,
			component: MDAOptionsAnalytics
		},
		[`${path}create-multicoy`]: {
			title: `Create MDA`,
			icon: MDAIcon,
			component: CreateMulticompany
		},
		[`${path}multi-company/prepare-bill`]: {
			title: `Prepare Bill`,
			icon: MDAIcon,
			component: MeteredBill
		},

		// Orba Market
		[`${path}multi-company/orba-market`]: {
			title: `Orba Market`,
			icon: MDAIcon,
			component: PrepareOrbaMarketbill
		},

		[`${path}multi-company/orba-market-bill`]: {
			title: `Orba Market`,
			icon: MDAIcon,
			component: OrbaMeteredBill
		},

		[`${path}multi-company/orba-market-edit`]: {
			title: `Edit Orba Market Bill`,
			icon: MDAIcon,
			component: EditOrbaMarketBill
		},
		[`${path}multi-company/orba-market-authorise`]: {
			title: `Authorise Orba Market Bill`,
			icon: MDAIcon,
			component: AuthoriseOrbaMarketBill
		},
		// Orba Market

		[`${path}multi-company/new-haven`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: NewHavenShops
		},
		[`${path}multi-company/new-haven-authorise`]: {
			title: `Authorise New Haven Shops`,
			icon: MDAIcon,
			component: AuthoriseNewHaven
		},
		[`${path}multi-company/create-new-haven`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: CreateNewHavenShops
		},
		[`${path}multi-company/water`]: {
			title: `Water`,
			icon: MDAIcon,
			component: Water
		},
		[`${path}multi-company/billing/view`]: {
			title: `View Bill`,
			icon: MDAIcon,
			component: ViewBill
		},
		[`${path}multi-company/billing/edit`]: {
			title: `Edit Bill`,
			icon: MDAIcon,
			component: EditBill
		},
		[`${path}multi-company/billing4NewHaven/view`]: {
			title: `View Bill`,
			icon: MDAIcon,
			component: ViewBill4NewHaven
		},
		[`${path}multi-company/authorise`]: {
			title: `Authorise Bill`,
			icon: MDAIcon,
			component: AuthoriseBill
		},
		[`${path}multi-company/billing4NewHaven/edit`]: {
			title: `Edit Bill`,
			icon: MDAIcon,
			component: EditBill4NewHaven
		},
		[`${path}Multi-company/New-haven-shops`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: Newhavenshops
		},
		[`${path}create-new-haven-shops`]: {
			title: `Add New Haven Shops`,
			icon: MDAIcon,
			component: Createnewhaven
		},
		// [`${path}multi-company/profile/view`]:{
		// 	title: `View Profile`,
		// 	icon: MDAIcon,
		// 	component: ViewProfile
		// },

		// [`${path}multi-company/profile/edit`]:{
		// 	title: `Edit Profile`,
		// 	icon: MDAIcon,
		// 	component: EditProfile
		// },
		//reducing-balance
		[`${path}reducing-balance/reducing-balance-receipts`]: {
			title: `Reducing Balance Receipt`,
			component: ReducingBalanceReceipt
		},
		[`${path}Maker-checker/authoriser`]: {
			title: `Authoriser`,
			component: Authoriser
		},
		[`${path}Maker-checker/inputter`]: {
			title: `Inputter`,
			component: Inputter
		},
		[`${path}Maker-checker/genreceipt`]: {
			title: `Generate Reciept`,
			component: GenReceipt
		},
		[`${path}addnew`]: {
			title: `Add New`,
			icon: MDAIcon,
			component: Addnew
		},
		[`${path}maker-checker/edit/:id`]: {
			title: `Edit-Maker-checker`,
			component: EditMaker
		},
		[`${path}maker-checker/view/:id`]: {
			title: `View-Maker-checker`,
			component: ViewMaker
		},
		[`${path}multi-company/billing`]: {
			title: `Bills`,
			component: Preparebill
		},
		[`${path}multi-company/mdaprofile`]: {
			title: `MDA Profile`,
			component: Profile
		},
		[`${path}multi-company/settings`]: {
			title: `Settings`,
			icon: UserManagementIcon,
			component: MultiCompanySettings
		},
		[`${path}hni/tax`]: {
			title: `HNI / HNI Tax`,
			component: HNIHome
		},
		[`${path}hni/setup`]: {
			title: `HNI / HNI Setup`,
			component: HNISetupHome
		},
		[`${path}hni/prepare-tax`]: {
			title: `Create HNI Tax`,
			component: HNITax
		},
		[`${path}hni/view`]: {
			title: `View HNI`,
			component: ViewHNI
		},
		[`${path}hni/edit`]: {
			title: `Edit HNI`,
			component: EditHNI
		},
		[`${path}hni/create-setup`]: {
			title: `Create Setup`,
			component: HNISetup
		},
		[`${path}hni/edit-setup`]: {
			title: `Edit Setup`,
			component: EditSetup
		},
		[`${path}hni/authorise`]: {
			title: `Authorise`,
			component: AuthoriseHNIBill
		},
		[`${path}create-jtb`]: {
			title: "Create JTB",
			component: CreateJTB
		},
		[`${path}user-management/manage-users`]: {
			title: "Manage Users",
			icon: UserManagementIcon,
			component: User
		},
		[`${path}user-management/manage-claims`]: {
			title: "Manage Claims",
			icon: UserManagementIcon,
			component: Claim
		},
		[`${path}user-management/statetin-agent`]: {
			title: `${coy_ANSSID} Agent`,
			icon: UserManagementIcon,
			component: ANSSIDAgent
		},
		[`${path}user-management/deactivate-agent`]: {
			title: "Deactivated Agent",
			icon: UserManagementIcon,
			component: DeactivatedAgents
		},
		[`${path}user-management/manage-roles`]: {
			title: "Manage Roles",
			icon: UserManagementIcon,
			component: Role
		},
		[`${path}direct-assessment/create-direct-assessment`]: {
			title: "Create Direct Assessment",
			icon: UserManagementIcon,
			component: CreateDirectAssessment
		},
		[`${path}direct-assessment/authorize-direct-assessment`]: {
			title: "Authorise Direct Assessment",
			icon: UserManagementIcon,
			component: Authorise
		},
		[`${path}direct-assessment/settings`]: {
			title: "Settings",
			icon: UserManagementIcon,
			component: DirectAssessmentSettings
		},
		[`${path}direct-assessment/records`]: {
			title: "Assessment Records",
			icon: UserManagementIcon,
			component: AssessmentRecords
		},
		[`${path}direct-assessment/match`]: {
			title: "Match Payment Ref & Bill Number",
			icon: UserManagementIcon,
			component: Match
		},
		[`${path}user-management/admin-change-password`]: {
			title: "Admin Change Password",
			icon: UserManagementIcon,
			component: AdminChangePassword
		},
		[`${path}create-user`]: {
			title: "Create User",
			icon: UserManagementIcon,
			component: CreateUser
		},
		[`${path}create-claim`]: {
			title: "Create Claim",
			component: CreateClaim
		},
		[`${path}create-role`]: {
			title: "Create Role",
			component: CreateRole
		},
		[`${path}corporate-profiling`]: {
			title: "Corporate Profiling",
			icon: MDAIcon,
			component: CorporateProfiling
		},
		[`${path}receipt`]: {
			title: "Receipt",
			icon: ReceiptIcon,
			component: Receipt
		},
		[`${path}receiptprt/receiptref`]: {
			title: "Generate Receipt by Payment Reference",
			icon: ReceiptIcon,
			component: ReceiptPymtRef
		},
		/*		[`${path}receipt/receiptours`]: {
			title: "Generate Receipt by Norlics Receipt Number",
			icon: ReceiptIcon,
			component: ReceiptPymtOurs
		},
		*/
		[`${path}receiptprt/receiptswitch`]: {
			title: "Generate Receipt by Provider Receipt Number",
			icon: ReceiptIcon,
			component: ReceiptPymtSwitch
		},
		[`${path}receiptprt/receiptinv`]: {
			title: "Generate Receipt by Vertical's Invoice Number",
			icon: ReceiptIcon,
			component: ReceiptPymtInv
		},
		[`${path}receiptprt/mda.receipt`]: {
			title: "Generate Mda receipt",
			icon: ReceiptIcon,
			component: Mdareceipt
		},
		[`${path}receiptprt/receipts`]: {
			title: "Generate Receipt",
			icon: ReceiptIcon,
			component: SingleInputReceipt
		},
		[`${path}receiptprt/payment-history`]: {
			title: "Get Payment History",
			icon: ReceiptIcon,
			component: PaymentHistory
		},
		[`${path}settings/id-details`]: {
			title: "ID Details",
			icon: SettingsIcon,
			component: IDDetails
		},
		[`${path}settings/statetin-setup`]: {
			title: `${coy_ANSSID} Setup`,
			icon: SettingsIcon,
			component: ANSSIDSetup
		},
		[`${path}settings/monthly-salary-range`]: {
			title: "Monthly Salary Range",
			icon: SettingsIcon,
			component: MonthlySalaryRange
		},
		[`${path}settings/revenue-details`]: {
			title: "Revenue Details",
			icon: SettingsIcon,
			component: RevenueDetails
		},
		[`${path}email-phone-change/record`]: {
			title: "Email/Phone Number Change Record",
			icon: EmailPhoneChangeIcon,
			component: EmailPhoneChangeRecord
		},
		[`${path}email-phone-change/change`]: {
			title: "Change Email And Phone Number",
			icon: EmailPhoneChangeIcon,
			component: ChangeEmailAndPhone
		},
		[`${path}email-phone-change/auth-requests/view`]: {
			title: "Authorise Email/Phone Change",
			icon: EmailPhoneChangeIcon,
			component: EmailAndPhoneChangeAuthView
		},
		[`${path}email-phone-change/auth-requests`]: {
			title: "Email/Phone Number Change Auth Requests",
			icon: EmailPhoneChangeIcon,
			component: EmailPhoneChangeAuthRequests
		},
		[`${path}email-phone-change/review-requests/view`]: {
			title: "Review Email/Phone Change",
			icon: EmailPhoneChangeIcon,
			component: EmailAndPhoneChangeReviewView
		},
		[`${path}email-phone-change/review-requests`]: {
			title: "Email/Phone Number Change Review Requests",
			icon: EmailPhoneChangeIcon,
			component: EmailPhoneChangeReviewRequests
		},
		[`${path}password`]: {
			title: "Change Password",
			icon: ChangePasswordIcon,
			component: ChangePassword
		},
		[`${path}individual-details`]: {
			title: "Details",
			component: UserDetails
		},
		[`${path}PAYE/records`]: {
			title: "PAYE Records",
			component: PayeRecords
		},
		[`${path}PAYE/PayeSchedule`]: {
			title: "PAYE Schedule",
			component: CreatePAYEAssessment
		},
		[`${path}Paye-Review`]: {
			title: "Paye Details",
			component: RecordsView
		},
		[`${path}PAYE/authorizer-review`]: {
			title: "AUTHORIZATION",
			component: AuthView
		},
		[`${path}PAYE/authorize-PAYE-assessment`]: {
			title: "PAYE Authorization",
			component: Authorisation
		},
		[`${path}PAYE-Reviewer`]: {
			title: " Review PAYE",
			component: Reviewer
		},
		[`${path}Paye-Reviewer-view`]: {
			title: "Paye Reviewer view",
			component: ReviewerView
		},
		[`${path}demand-notice/consoldemandnotice`]: {
			title: "Consolidated Demand Notice",
			component: Consolidated
		},
		[`${path}demand-notice/generate-consoldemandnotice`]: {
			title: "Generate Consolidated Demand Notice",
			component: GenerateConsolidated
		},
		[`${path}demand-notice/ConsolidatedView`]: {
			title: "Consolidated Read Only",
			component: ConsolidatedView
		},
		[`${path}PAYE-Assessment`]: {
			title: "PAYE-Assessment",
			component: PAYE
		},
		[`${path}Assessment`]: {
			title: "PAYE-Assessment Continuation",
			component: PAYEAssessment
		}
	};

	const routeBucket = isUser ? routes : adminRoutes;

	const route = routeBucket[pathname];

	const firstAllowedRoute = Object.keys(allowedRoutes || {}).find((key) =>
		Boolean(routeBucket[key])
	);

	const comp = routeBucket[firstAllowedRoute];

	useEffect(() => {
		function handleResize() {
			toggleSidebar(window.innerWidth > 768);
		}

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	function handleSidebarToggle() {
		if (window.innerWidth <= 768) {
			toggleSidebar(!sidebarOpen);
		}
	}

	return (
		<section className={styles.wrapper}>
			{sidebarOpen &&
				(isUser ? (
					<UserSidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				) : (
					<Sidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				))}
			<div className={styles.faux__sidenav} />
			<div className={styles.main__right}>
				<Header
					title={route?.title}
					icon={route?.icon && route?.icon}
					toggleSidebar={handleSidebarToggle}
				/>
				<Switch>
					<Route path={path} exact>
						{isUser ? (
							<Home />
						) : !hasDashboard && !isGovernor && comp?.component ? (
							<comp.component />
						) : isGovernor ||
						  userInfo?.account_type === "admin" ||
						  userInfo?.account_type === "Dashboard-Only" ? (
							<SpecialDashboard />
						) : (
							<Summary />
						)}
					</Route>
					{Object.keys(routeBucket).map((r) => {
						const Component = routeBucket[r]?.component;
						return (
							Component && (
								<Route key={r} path={r}>
									<Component />
								</Route>
							)
						);
					})}
				</Switch>
			</div>
		</section>
	);
};

export default Dashboard;
