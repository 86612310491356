import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const JBTs = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const headers = [
		["JTB Tin", "jtbTin"],
		["TCC No", "tccNo"],
		["Tax Period", "taxPeriod"],
		["Turn Over", "turnOver"],
		["Accessible Profit", "accessibleProfit"],
		["Total Profit", "totalProfit"],
		["Tax Payable", "taxPayable"],
		["Tax Paid", "taxPaid"],
		["Tax Authority", "taxAuthority"],
		["Tax Office", "taxOffice"]
	];

	const getJTBs = async () => {
		setLoading(true);
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/admin/jtb`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			if (info) {
				console.log(info);
				setData(info);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getJTBs();
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button style={{ visibility: "hidden" }}>
					View Entire Table
				</button>
				<NavLink to="/create-jtb">Create JTB</NavLink>
			</div>
			<div className={styles.table__div}>
				<Table
					headers={headers}
					loading={loading}
					data={data}
					full
					onPageChanged={getJTBs}
				/>
			</div>
		</div>
	);
};

export default JBTs;
