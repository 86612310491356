import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Table from "../../components/Table";
import RegularModal from "../../components/Modal/regular";
import Alert from "../../components/Alert";
import styles1 from "../../Dashboard/MDAs/style.module.css";
import styles from "./style.module.css";
import anssidStyles from "../../Dashboard/StateTIN/style.module.css";
import { exportExcel, remapObj } from "../../utils";

const AssessmentRecords = () => {
	const userId = JSON.parse(localStorage.getItem("user_info")).id;
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [ESBN, setESBN] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [revenue, setRevenue] = useState("");
	const [ProcessStatus, setProcessStatus] = useState("");

	const [PaymentStatus, setPaymentStatus] = useState("");
	const [assessmentID, setAssessmentID] = useState("");
	const [taxYear, setTaxYear] = useState("");
	const [inputter, setInputter] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const [AssessmentYear, setAssessmentYear] = useState("");
	const [revokeModalStatus, setRevokeModalStatus] = useState({
		revoking: false,
		showing: false,
		data: null
	});
	const [billNoOfAmountPaidShowing, setBillNoOfAmountPaidShowing] =
		useState("");
	const [amountPaid, setAmountPaid] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const filterRef = createRef();

	const headers = [
		[`ESBN`, "ESBN"],
		["Name", "Name"],
		["Bill Number", "AssessmentCode"],
		["tax Office", "AssessmentTaxOffice"],
		["Assess year", "AssessmentYear"],
		["Total tax Due", "TotalTaxDue"],
		["Process Status", "ProcessStatus"],
		["Payment Status", "PaymentStatus"],
		["Inputter", "Inputter"]
	];

	const getAssessmentRecords =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			if (ESBN) {
				query += `ESBN=${ESBN}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (name) {
				query += `Name=${name}&`;
			}

			if (phoneNumber) {
				query += `phone=${phoneNumber}&`;
			}

			if (revenue) {
				query += `Revenue=${revenue}&`;
			}

			if (taxYear) {
				query += `TaxYear=${taxYear}&`;
			}

			if (ProcessStatus) {
				query += `ProcessStatus=${ProcessStatus}&`;
			}

			if (PaymentStatus) {
				query += `PaymentStatus=${PaymentStatus}&`;
			}

			if (assessmentID) {
				query += `AssessmentId=${assessmentID}&`;
			}

			if (inputter) {
				query += `Inpter=${inputter}&`;
			}
			if (AssessmentYear) {
				query += `AssessmentYear=${AssessmentYear}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let { data, total } = await axios
					.get(`/dareports/daspoolallrecords?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data || { data: {} });

				if (!data) {
					// handle errors
					return null;
				}

				setData(data);
				setTotal(total);

				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	const revoke = async () => {
		setRevokeModalStatus((revokeStatus) => ({
			...revokeStatus,
			revoking: true
		}));

		try {
			const { msg } = await axios
				.put("tmsprocess", {
					billCode: revokeModalStatus.data.AssessmentCode,
					processUpd: "3",
					MDA_Flg: "01",
					coy: "00",
					userid: userId
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg
			});

			getAssessmentRecords()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRevokeModalStatus({
				revoking: false,
				showing: false,
				data: null
			});
		}
	};

	const getAmountPaid = async (billNumber) => {
		try {
			const amountPaid = await axios
				.get(`account/bill/payment/${billNumber}`)
				.then((res) => res.data.data);

			setAmountPaid(amountPaid);
		} catch (error) {
			setAmountPaid("");
			setBillNoOfAmountPaidShowing("");
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const clearFilters = () => {
		setESBN("");
		setStartDate("");
		setEndDate("");
		setName("");
		setPhoneNumber("");
		setRevenue("");
		setProcessStatus("");
		setPaymentStatus("");
		setAssessmentID("");

		filterRef.current.classList.toggle(styles1.no__display);
		setFiltersCleared(true);
	};

	const exportAnalytics = async () => {
		const info = await getAssessmentRecords({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		if (!info) console.log("What da hell!");
		exportExcel(remapObj(info, headers), headers, `Assessment Report.xlsx`);
	};

	useEffect(() => {
		getAssessmentRecords()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles1.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{revokeModalStatus.showing && (
				<RegularModal
					showing={revokeModalStatus.showing}
					toggle={setRevokeModalStatus}
				>
					<p className={styles.revoke__warning}>
						Are you sure want to revoke assessment with Assessment
						Code: <b>{revokeModalStatus.data.AssessmentCode}</b>
					</p>
					<div className={styles.revoke__btns}>
						<button
							onClick={() =>
								setRevokeModalStatus({
									showing: false,
									ESBN: null
								})
							}
							className="secondary__btn"
						>
							Cancel
						</button>
						<button onClick={revoke}>
							{revokeModalStatus.revoking
								? "Revoking..."
								: "Revoke"}
						</button>
					</div>
				</RegularModal>
			)}
			<div className={styles1.actions__div}>
				<button
					className={["primary__btn", anssidStyles.create__btn].join(
						" "
					)}
					onClick={() => {
						history.push(
							"/direct-assessment/create-direct-assessment"
							// {
							// 	taxpayers: true,
							// 	fromIndividual: true,
							// 	ssActive: "1"
							// }
						);
					}}
				>
					Create Assessment
				</button>

				<button
					className={[
						"secondary__btn",
						anssidStyles.create__btn
					].join(" ")}
					onClick={() => exportAnalytics()}
					// disabled={loading}
				>
					Download Report
				</button>

				<div className={styles1.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles1.no__display
							);
						}}
						className={styles1.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles1.filter__div,
							styles1.no__display
						].join(" ")}
					>
						<div className={styles1.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles1.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles1.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!ESBN &&
								!startDate &&
								!endDate &&
								!name &&
								!phoneNumber &&
								!revenue &&
								!ProcessStatus &&
								!PaymentStatus &&
								!assessmentID &&
								!taxYear
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="ESBN">ESBN</label>
								<input
									name="ESBN"
									id="ESBN"
									type="text"
									value={ESBN}
									onChange={(e) => setESBN(e.target.value)}
									placeholder="Enter ESBN"
								/>
							</div>
						</form>
						<p>By Taxpayer</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="name">Name</label>
								<input
									name="name"
									id="name"
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Eg Ada Agu"
								/>
							</div>
							<div>
								<label htmlFor="phoneNumber">
									Taxpayer Phone
								</label>
								<input
									name="phoneNumber"
									id="phoneNumber"
									type="number"
									value={phoneNumber}
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
									placeholder="Eg. +2348012345678"
								/>
							</div>
							<div>
								<label htmlFor="revenue">Revenue</label>
								<input
									name="revenue"
									id="revenue"
									type="text"
									value={revenue}
									onChange={(e) => setRevenue(e.target.value)}
									placeholder="Eg. 6836472"
								/>
							</div>
							<div>
								<label htmlFor="ProcessStatus">
									Process Status
								</label>
								<select
									name="ProcessStatus"
									id="ProcessStatus"
									value={ProcessStatus}
									onChange={(e) =>
										setProcessStatus(e.target.value)
									}
								>
									<option value="">Select</option>
									<option>Approved</option>
									<option>Revoked</option>
									<option>Pending</option>
									<option>Declined</option>
								</select>
							</div>
							<div>
								<label
									htmlFor="PaymentStatus
"
								>
									Payment Status
								</label>
								<select
									name="PaymentStatus
"
									id="PaymentStatus
"
									value={PaymentStatus}
									onChange={(e) =>
										setPaymentStatus(e.target.value)
									}
								>
									<option value="">Select</option>
									<option>Approved</option>
									<option>Revoked</option>
									<option>Pending</option>
									<option>Declined</option>
								</select>
							</div>
							<div>
								<label htmlFor="assessmentID">
									Bill Number
								</label>
								<input
									name="assessmentID"
									id="assessmentID"
									type="text"
									value={assessmentID}
									onChange={(e) =>
										setAssessmentID(e.target.value)
									}
									placeholder="Eg. 6836472"
								/>
							</div>
							<div>
								<label htmlFor="taxYear">Tax Year</label>
								<input
									name="taxYear"
									id="taxYear"
									type="text"
									value={taxYear}
									onChange={(e) => setTaxYear(e.target.value)}
									placeholder="Eg. 2023"
								/>
							</div>
							<div>
								<label htmlFor="AssessmentYear">
									Assessment Year
								</label>
								<input
									name="AssessmentYear"
									id="AssessmentYear"
									type="text"
									value={AssessmentYear}
									onChange={(e) =>
										setAssessmentYear(e.target.value)
									}
									placeholder="Eg. 2023"
								/>
							</div>
						</form>
						<p>By Inputter</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="inputter">Inputter</label>
								<input
									name="inputter"
									id="inputter"
									type="email"
									value={inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
									placeholder="Enter Inputter email"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles1.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getAssessmentRecords()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			{data && (
				<p style={{ textAlign: "right" }}>
					<span style={{ color: "red" }}>*</span> Click each row to
					show amount paid from total bill amount.
				</p>
			)}
			<div className={styles1.table__div}>
				<Table
					headers={headers}
					loading={loading}
					data={data}
					full
					pageSize={20}
					total={total}
					onPageChanged={getAssessmentRecords}
					onRowClick={(data) => {
						setAmountPaid("");
						setBillNoOfAmountPaidShowing(data.AssessmentCode);
						getAmountPaid(data.AssessmentCode);
					}}
					billNoOfAmountPaidShowing={billNoOfAmountPaidShowing}
					setBillNoOfAmountPaidShowing={setBillNoOfAmountPaidShowing}
					amountPaid={amountPaid}
					onEdit={(data) => {
						history.push(
							`/direct-assessment/create-direct-assessment`,
							{
								...data,
								ESBN: data.ESBN,
								edit: true
							}
						);
					}}
					onView={(data) => {
						history.push(
							`/direct-assessment/create-direct-assessment`,
							{ ...data, ESBN: data.ESBN, view: true }
						);
					}}
					onOthers={(data) =>
						setRevokeModalStatus((revokeStatus) => ({
							...revokeStatus,
							showing: true,
							data
						}))
					}
				/>
			</div>
		</div>
	);
};

export default AssessmentRecords;
