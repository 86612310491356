import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styles from "./style.module.css";
import downloadIcon from "../../assets/downloadFilingIcon.svg";
import Table from "../../components/Table";
import { ApprovalModal } from "./modals/approvalModal";
import RegularModal from "../../components/Modal/regular";
import { H1Document } from "./modals/h1Document";
import Alert from "../../components/Alert";
import { useHistory } from "react-router";
import { formatAmount, formatDate } from "../../utils";
import ApproveRejectWidget from "../../components/ApproveRejectWidget";
import GoBackButton from "../../components/goBackButton";

const headers = [
    ["Employee Name", "fullName"],
    ["Months in Service", "monthsInService"],
    ["Employee ESBN", "eSBN"],
    ["Total Gross Salary", "annualGrossSalary", formatAmount],
    ["Total Reliefs (₦)", "annualCalcTotalRelief", formatAmount],
    ["Annual Tax Due (₦)", "annualCalcTaxDue", formatAmount]
];

const remitanceTableHeaders = [
    ["Name", "payerName"],
    ["Receipt no.", "receiptNumber"],
    ["Bill Number", "billNumber"],
    ["Amount (₦)", "amount", formatAmount],
    ["Payment Date", "paymentDate", formatDate],
    ["Revenue Source", "revenueSource"]
];

function getStatusText(approveRejectFlag) {
    // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval; 6: Deleted
    if (approveRejectFlag === "0") {
        return {
            status: "Pending",
            classname: styles.yellow
        };
    } else if (approveRejectFlag === "1") {
        return {
            status: "Cancelled",
            classname: styles.red
        };
    } else if (approveRejectFlag === "2") {
        return {
            status: "Declined",
            classname: styles.red
        };
    } else if (approveRejectFlag === "3") {
        return {
            status: "Approved",
            classname: styles.green
        };
    } else if (approveRejectFlag === "4") {
        return {
            status: "Revoked",
            classname: styles.red
        };
    } else if (approveRejectFlag === "5") {
        return {
            status: "Pending Approval",
            classname: styles.yellow
        };
    } else {
        return {
            status: "Deleted",
            classname: styles.red
        };
    }
}

const handleActionButtonsRender = (approveRejectFlag, viewer) => {
    if (viewer === "self-service" || viewer === "admin-filer") {
        if (approveRejectFlag === "0") {
            return { state: true, action: 1 };
        } else if (approveRejectFlag === "1") {
            return { state: true, action: 6 };
        } else {
            return { state: false, action: 6 };
        }
    }
    // reviewer
    if (viewer === "reviewer") {
        if (approveRejectFlag === "0") {
            return { state: true, action: 0 };
        } else {
            return { state: false, action: 0 };
        }
    }
    // authoriser
    if (viewer === "authoriser") {
        if (approveRejectFlag === "5") {
            return { state: true, action: 5 };
        } else if (approveRejectFlag === "3") {
            return { state: true, action: 3 };
        } else {
            return { state: false, action: 0 };
        }
    }
};

const handleNegativeActionMapping = (approvalFlag, viewer) => {
    if (viewer === "reviewer") return "2";
    if (viewer === "authoriser") return approvalFlag === "3" ? 4 : 2;
};

const handleButtonAction = (approveRejectFlag, isNegative, viewer) => {
    if (approveRejectFlag === "0") {
        return isNegative ? isNegative : 5;
    } else if (approveRejectFlag === "1") {
        return 6;
    } else if (approveRejectFlag === "2") {
        return 6;
    } else if (approveRejectFlag === "3") {
        return handleNegativeActionMapping(approveRejectFlag, viewer);
    } else if (approveRejectFlag === "4") {
        return 6;
    } else if (approveRejectFlag === "5") {
        return handleNegativeActionMapping(approveRejectFlag, viewer) || 3;
    } else {
        return "6";
    }
};

export const CoporateViewFiling = () => {
    const [showModal, setShowModal] = useState({
        modalState: false,
        approvalState: ""
    });
    const [H1File, setH1File] = useState([]);
    const [processingPositive, setProcessingPositive] = useState(false);
    const [processingNegative, setProcessingNegative] = useState(false);
    const [positiveActionComment, setPositiveActionComment] = useState('');
    const [negativeReason, setNegativeReason] = useState("");

    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });

    const history = useHistory();

    const [downloadH1ModalStatus, setDownloadH1ModalStatus] = useState({
        downloading: false,
        showing: false,
        data: null
    });

    const totalTaxObtainable =
        downloadH1ModalStatus?.data?.data?.EmployeeDetails?.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.annualCalcTaxDue),
            0
        );
    const devlopmentLevy = downloadH1ModalStatus?.data?.data?.EmployeeDetails
        ?.length
        ? downloadH1ModalStatus?.data?.data?.EmployeeDetails?.length * 500
        : 0;

    const totalTaxDue = totalTaxObtainable + devlopmentLevy;

    const totalAmountPaid = downloadH1ModalStatus?.data?.data?.billNumbers
        ?.filter((val) => val?.billNumber === "")
        .reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.amount),
            0
        );

    const { state } = useLocation();
    const { coy, corporateCompanyID, viewer } = state;

    const rejectAnnualReturns = async (rejectionReason) => {
        try {
            const accessToken = localStorage.getItem("access_token");
            let data = await axios
                .put(
                    `company/annual-returns/${coy}/reject`,
                    {
                        reason: rejectionReason
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((res) => res.data || { data: {} });

            if (!data || !data?.data) {
                return null;
            }
            if (data) {
                setAlert({
                    showing: true,
                    type: "success",
                    message: data.message
                });
                setShowModal({
                    ...showModal,
                    modalState: false
                });
            }
        } catch (error) {
            setAlert({
                showing: true,
                type: "error",
                message:
                    error?.response?.data?.message ?? "Rejection Unsuccessful!"
            });
        }
    };

    const getH1 = async (coy, corporateCompanyID) => {
        try {
            const accessToken = localStorage.getItem("access_token");
            let data = await axios
                .get(
                    `corporate/company/annual-returns/${coy}/${corporateCompanyID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((res) => res.data || { data: {} });

            if (!data || !data?.data) {
                return null;
            }
            if (data) {
                setDownloadH1ModalStatus((h1Modal) => ({
                    ...h1Modal,
                    data: data
                }));
            }
        } catch (error) {
            setAlert({
                showing: true,
                type: "error",
                message:
                    error?.response?.data?.message ??
                    "H1 Data Fetching Unsuccessful!"
            });
        }
    };

    const handleApproval = async (approvalStatus = "0", reason = "") => {
        const approvalIsPositive =
            approvalStatus === "0" ||
            approvalStatus === "3" ||
            approvalStatus === "5";

        if (approvalIsPositive) {
            setProcessingPositive(true);
        } else {
            setProcessingNegative(true);
        }

        const payload = {
            globId: "8",
            approve: approvalStatus,
            reason4Reject: reason,
            recId: downloadH1ModalStatus?.data?.data?.annualReturnInfo?.id
        };

        try {
            await axios.put(`/globalprocesswithnobill`, payload).then((res) => {
                setAlert({
                    showing: true,
                    type: "success",
                    message: res?.data.msg
                });
                setTimeout(() => {
                    history.goBack();
                }, 2000);

                if (approvalIsPositive) {
                    setProcessingPositive(false);
                } else {
                    setProcessingNegative(false);
                }
            });
        } catch (error) {
            if (approvalIsPositive) {
                setProcessingPositive(false);
            } else {
                setProcessingNegative(false);
            }
            setAlert({
                showing: true,
                type: "error",
                message:
                    error?.response?.data?.msg ??
                    error?.response?.data?.message ??
                    "H1 Data Fetching Unsuccessful!"
            });
        }
    };

    const printH1File = () => {
        if (H1File) {
            const w = window.open();
            if (w.document) {
                w.document.write(H1File);
                w.document.close();
                w.onload = () => {
                    w.focus();
                    w.print();
                };

                w.onafterprint = () => {
                    w.close();
                };
            } else {
                return;
            }
        } else {
            return;
        }
    };

    useEffect(() => {
        if (coy && corporateCompanyID) {
            getH1(coy, corporateCompanyID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);

    return (
        <>
            <div className={styles.view__filing__wrapper}>
                <GoBackButton />
                {alert.showing && (
                    <Alert text={alert.message} type={alert.type} />
                )}
                {downloadH1ModalStatus.showing && (
                    <RegularModal showing={downloadH1ModalStatus.showing}>
                        <H1Document
                            data={downloadH1ModalStatus?.data}
                            getFile={(file) => setH1File(file)}
                        />
                        <div className={styles.action__btns}>
                            <button
                                className={`btn btn-danger`}
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <p>Close Modal</p>
                            </button>
                            <button
                                className={`btn btn-success ${styles.download__btn}`}
                                onClick={() => printH1File()}
                            >
                                <p>Download H1 Details</p>
                                <img src={downloadIcon} alt="download" />
                            </button>
                        </div>
                    </RegularModal>
                )}
                {showModal.modalState && (
                    <ApprovalModal
                        showing={showModal.modalState}
                        closeModal={(value) =>
                            setShowModal({
                                modalState: value,
                                approvalState: ""
                            })
                        }
                        rejectionAction={(reason) =>
                            rejectAnnualReturns(reason)
                        }
                        enterprise={
                            downloadH1ModalStatus?.data?.data?.CompanyDetails
                                ?.name
                        }
                        approvalState={showModal.approvalState}
                    />
                )}
                <div>
                    <div className={`bg-warning ${styles.complaint}`}>
                        <p>
                            TODAY'S DATE IS {new Date()?.toLocaleDateString()}{" "}
                            {downloadH1ModalStatus?.data?.data?.CompanyDetails?.name.toUpperCase()}{" "}
                            IS COMPLAINT:
                        </p>
                        <p>
                            <strong>
                                BELOW ARE THE DETAILS OF RELEVANT DOCUMENTS
                            </strong>
                        </p>
                    </div>
                </div>
                <div className={styles.view__filing__header}>
                    <p className={styles.header__title}>
                        {
                            downloadH1ModalStatus?.data?.data?.CompanyDetails
                                ?.name
                        }
                    </p>
                    <button
                        className={`btn btn-success ${styles.download__btn}`}
                        onClick={() =>
                            setDownloadH1ModalStatus((h1Modal) => ({
                                ...h1Modal,
                                showing: true
                            }))
                        }
                    >
                        <img src={downloadIcon} alt="download" />
                        <p>Download H1 Details</p>
                    </button>
                </div>
                <div className={styles.filingDetails}>
                    <div className={styles.aboutFiling}>
                        <p>Total Tax Due: ₦{formatAmount(totalTaxDue)}</p>
                        <p className={styles.red}>
                            Variance:{" "}
                            <strong>
                                ₦{formatAmount(totalTaxDue - totalAmountPaid)}
                            </strong>
                        </p>
                        <p>
                            Review Status:{" "}
                            <strong
                                className={
                                    getStatusText(
                                        downloadH1ModalStatus?.data?.data
                                            ?.annualReturnInfo?.approveFlg
                                    ).classname
                                }
                            >
                                {downloadH1ModalStatus?.data?.data
                                    ?.annualReturnInfo?.approveFlg
                                    ? parseInt(
                                        downloadH1ModalStatus?.data?.data
                                            ?.annualReturnInfo?.approveFlg
                                    ) > 2
                                        ? "REVIEWED"
                                        : getStatusText(
                                            downloadH1ModalStatus?.data
                                                ?.data?.annualReturnInfo
                                                ?.approveFlg
                                        ).status?.toUpperCase()
                                    : "..."}
                            </strong>
                        </p>
                        <p>
                            Approval Status:{" "}
                            <strong
                                className={
                                    getStatusText(
                                        downloadH1ModalStatus?.data?.data
                                            ?.annualReturnInfo?.approveFlg
                                    ).classname
                                }
                            >
                                {downloadH1ModalStatus?.data?.data
                                    ?.annualReturnInfo?.approveFlg
                                    ? getStatusText(
                                        downloadH1ModalStatus?.data?.data
                                            ?.annualReturnInfo?.approveFlg
                                    ).status?.toUpperCase()
                                    : "..."}
                            </strong>
                        </p>
                        <p>
                            Type Of Filing:{" "}
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data
                                        ?.annualReturnInfo?.filingType
                                }
                            </strong>
                        </p>
                        <p>
                            Filing Year:{" "}
                            <strong>
                                {downloadH1ModalStatus?.data?.data
                                    ?.annualReturnInfo?.filingYear ?? "N/A"}
                            </strong>
                        </p>
                        {downloadH1ModalStatus?.data?.data?.annualReturnInfo
                            ?.filingType === "Monthly" && (
                                <p>
                                    Filing Month{" "}
                                    <strong>
                                        {
                                            downloadH1ModalStatus?.data?.data
                                                ?.annualReturnInfo?.filingTypeMonth
                                        }
                                    </strong>
                                </p>
                            )}
                        {downloadH1ModalStatus?.data?.data?.annualReturnInfo
                            ?.filingType === "Period" && (
                                <>
                                    <p>
                                        Start Date:{" "}
                                        <strong>
                                            {downloadH1ModalStatus?.data?.data
                                                ?.annualReturnInfo
                                                ?.filingPeriodStartDate ?? "N/A"}
                                        </strong>
                                    </p>
                                    <p>
                                        End Date:{" "}
                                        <strong>
                                            {downloadH1ModalStatus?.data?.data
                                                ?.annualReturnInfo
                                                ?.filingPeriodEndDate ?? "N/A"}
                                        </strong>
                                    </p>
                                </>
                            )}
                    </div>
                    <div className={styles.aboutCompany}>
                        <p>
                            ESBN:{" "}
                            <strong>
                                {downloadH1ModalStatus?.data?.data?.esbn}
                            </strong>
                        </p>
                        <p>
                            Sector:{" "}
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data
                                        ?.CompanyDetails?.business_Category
                                }
                            </strong>
                        </p>
                        <p>
                            Company Name:{" "}
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data
                                        ?.CompanyDetails?.name
                                }
                            </strong>
                        </p>
                        <p>
                            Address:{" "}
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data
                                        ?.CompanyDetails?.state_Office_Address
                                }
                            </strong>
                        </p>
                        <p>
                            Phone Number:{" "}
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data
                                        ?.CompanyDetails?.phone_Number
                                }
                            </strong>
                        </p>
                    </div>
                </div>

                <div className="table-responsive">
                    <br />
                    <h5 className={styles.entries__title}>
                        Employee entries preview
                    </h5>

                    <Table
                        headers={headers}
                        data={
                            downloadH1ModalStatus?.data?.data
                                ?.EmployeeDetails || []
                        }
                        full
                        pageSize={20}
                        total={
                            downloadH1ModalStatus?.data?.data?.EmployeeDetails
                                ?.length
                        }
                    />
                </div>

                <br />

                <div className={`row ${styles.entries__deets}`}>
                    <div className={`col-md-3`}>
                        <img
                            className={styles.entries__title__signature}
                            src={
                                downloadH1ModalStatus?.data?.data?.signature
                                    ?.signature
                            }
                            alt="Signature"
                        />
                        <p>Signature</p>
                    </div>
                    <div className={`col-md-3`}>
                        <p>
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data?.signature
                                        ?.name
                                }
                            </strong>
                        </p>
                        <p>Name</p>
                    </div>
                    <div className={`col-md-3`}>
                        <p>
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data?.signature
                                        ?.designation
                                }
                            </strong>
                        </p>
                        <p>Designation</p>
                    </div>
                    <div className={`col-md-3`}>
                        <p>
                            <strong>
                                {
                                    downloadH1ModalStatus?.data?.data?.signature
                                        ?.dateFiled
                                }
                            </strong>
                        </p>
                        <p>Date Filed</p>
                    </div>
                </div>
                <br />
                <br />
                <br />
                {downloadH1ModalStatus?.data?.data?.billNumbers?.length && (
                    <>
                        <h5 className={styles.entries__title}>
                            Reciept Number for each remittance made in{" "}
                            {
                                downloadH1ModalStatus?.data?.data?.signature
                                    ?.dateFiled
                            }
                        </h5>
                        <br />

                        {downloadH1ModalStatus?.data?.data?.billNumbers
                            ?.length ? (
                            <>
                                <Table
                                    headers={remitanceTableHeaders}
                                    data={
                                        downloadH1ModalStatus?.data?.data
                                            ?.billNumbers
                                    }
                                    full
                                    pageSize={20}
                                    total={
                                        downloadH1ModalStatus?.data?.data
                                            ?.billNumbers?.length
                                    }
                                />
                                <br />
                                <hr />
                                <br />
                            </>
                        ) : (
                            <strong>Total Tax Due</strong>
                        )}
                    </>
                )}

                <h5 className={styles.entries__title}>VARIANCE</h5>
                <br />

                <div className={`row ${styles.variants__deets}`}>
                    <div className={`col-md-4`}>
                        <p>
                            <strong>Total Tax Due</strong>
                        </p>
                        <p>₦{formatAmount(totalTaxDue)}</p>
                    </div>
                    <div className={`col-md-4`}>
                        <p>
                            <strong>Total Payment</strong>
                        </p>
                        <p>₦{formatAmount(totalAmountPaid)}</p>
                    </div>
                    <div className={`col-md-4`}>
                        <p>
                            <strong>Variance</strong>
                        </p>
                        <p>₦{formatAmount(totalTaxDue - totalAmountPaid)}</p>
                    </div>
                </div>
                <br />
                <hr />

                <br />
                <h5 className={styles.entries__title}>
                    SUPPORTING DOCUMENTS ATTACHED
                </h5>

                <div className={`bg-warning ${styles.py__2}`}>
                    <small>
                        Click on the file name to view or download document
                    </small>
                </div>
                <br />
                <div className={`row ${styles.variants__deets}`}>
                    {downloadH1ModalStatus?.data?.data?.docs?.slice(0, 2) &&
                        downloadH1ModalStatus?.data?.data?.docs
                            ?.slice(0, 2)
                            ?.map((doc) => (
                                <Fragment key={doc?.id}>
                                    <div className={`col-md-5`}>
                                        <p>
                                            <strong>
                                                Payment Receipt:{" "}
                                                {doc?.description} (Max: 1mb){" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </strong>
                                        </p>
                                        {doc?.data ? (
                                            <a href={doc?.data} download>
                                                File.pdf
                                            </a>
                                        ) : (
                                            <p style={{ color: "red" }}>N/A</p>
                                        )}
                                    </div>
                                </Fragment>
                            ))}
                </div>

                <div className={`row ${styles.variants__deets}`}>
                    {downloadH1ModalStatus?.data?.data?.docs?.slice(2, 4) &&
                        downloadH1ModalStatus?.data?.data?.docs
                            ?.slice(2, 4)
                            ?.map((doc) => (
                                <Fragment key={doc?.id}>
                                    <div className={`col-md-5`}>
                                        <p>
                                            <strong>
                                                Payment Receipt:{" "}
                                                {doc?.description} (Max: 1mb){" "}
                                                <span style={{ color: "red" }}>
                                                    *
                                                </span>
                                            </strong>
                                        </p>
                                        {doc?.data ? (
                                            <a href={doc?.data} download>
                                                File.pdf
                                            </a>
                                        ) : (
                                            <p style={{ color: "red" }}>N/A</p>
                                        )}
                                    </div>
                                </Fragment>
                            ))}
                </div>
                {downloadH1ModalStatus?.data?.data?.docs?.length === 5 && (
                    <div className={`row ${styles.variants__deets}`}>
                        {downloadH1ModalStatus?.data?.data?.docs?.slice(4, 5) &&
                            downloadH1ModalStatus?.data?.data?.docs
                                ?.slice(4, 5)
                                ?.map((doc) => (
                                    <Fragment key={doc?.id}>
                                        <div className={`col-md-5`}>
                                            <p>
                                                <strong>
                                                    Payment Receipt:{" "}
                                                    {doc?.description} (Max:
                                                    1mb){" "}
                                                    <span
                                                        style={{ color: "red" }}
                                                    >
                                                        *
                                                    </span>
                                                </strong>
                                            </p>
                                            {doc?.data ? (
                                                <a href={doc?.data} download>
                                                    File.pdf
                                                </a>
                                            ) : (
                                                <p style={{ color: "red" }}>
                                                    N/A
                                                </p>
                                            )}
                                        </div>
                                    </Fragment>
                                ))}
                    </div>
                )}
                <br />
                <br />
                <br />
                {handleActionButtonsRender(
                    downloadH1ModalStatus?.data?.data?.annualReturnInfo
                        ?.approveFlg,
                    viewer
                )?.state && (
                        <ApproveRejectWidget
                            type={
                                handleActionButtonsRender(
                                    downloadH1ModalStatus?.data?.data
                                        ?.annualReturnInfo?.approveFlg,
                                    viewer
                                )?.action
                            }
                            negativeIdiotProofQuestion={` this Annual Filing with ${downloadH1ModalStatus?.data?.data?.esbn} ESBN?`}
                            processingNegative={processingNegative}
                            negativeReason={negativeReason}
                            setNegativeReason={setNegativeReason}
                            reasonMessage={"Please provide your reason(s)."}

                            positiveIdiotProofQuestion={` this Annual Filing with ${downloadH1ModalStatus?.data?.data?.esbn} ESBN`}
                            positiveActionComment={positiveActionComment}
                            setPositiveActionComment={setPositiveActionComment}
                            processingPositive={processingPositive}
                            // For API calls
                            negativeFunction={(e, negativeReason) => {
                                e?.preventDefault();
                                handleApproval(
                                    `${handleButtonAction(
                                        downloadH1ModalStatus?.data?.data
                                            ?.annualReturnInfo?.approveFlg,
                                        viewer === "reviewer" ? "2" : "1",
                                        viewer
                                    )}`,
                                    negativeReason
                                );
                            }}
                            positiveFunction={() => {
                                handleApproval(
                                    `${handleButtonAction(
                                        downloadH1ModalStatus?.data?.data
                                            ?.annualReturnInfo?.approveFlg
                                    )}`,
                                    positiveActionComment
                                );
                            }}
                        />
                    )}
            </div>
        </>
    );
};
