import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../utils";
import Table from "../../../components/Table";
import PaymentModal from "../../../components/Modal/payment.modal";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import Alert from "../../../components/Alert";

const CorporatePayeRecord = () => {
	const corporateESBN = JSON.parse(localStorage.getItem("user_info")).anssid;
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [billNumber, setBillNumber] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getAssessmentRecords =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);

			let query = `page=${currentPage}&pageSize=${pageSize}&`;
			try {
				const response = await axios.get(
					`/dareports/tmspayedisplay?${query}ESBN=${corporateESBN}`
				);

				const { data, total } = response.data || {};
				if (!data) {
					return null;
				}
				setData(data);
				setTotal(total);
				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "success",
						message: error.response.data?.msg
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const getStatusColor = (status) => {
		switch (status) {
			case "Approved":
				return "#2F9959";
			case "Pending Review":
				return "brown";
			case "PAID":
				return "#2F9959";
			case "Revoked":
				return "#D73E3E";
			case "Cancel":
				return "#D73E3E";
			case "Pending":
				return "#F99601";
			case "Pending Approval":
				return "#F99601";
			default:
				return "black";
		}
	};

	const processStatusMap = {
		0: "Pending Review",
		1: "Cancel",
		2: "Decline",
		3: "Approved",
		4: "Revoked",
		5: "Pending Approval",
		6: "Delete"
	};

	const getModeOfFilingText = (modeOfFiling) => {
		if (modeOfFiling === "0") {
			return "Self-Service";
		} else if (modeOfFiling === "1") {
			return "Admin";
		} else {
			return "";
		}
	};

	const modifiedData = data.map((item) => {
		const ProcessStatusFlag = processStatusMap[item.ApproveFlg] || "";
		const ModeOfFilingFlag = getModeOfFilingText(item.ModeOfFiling) || "";

		return {
			...item,
			ProcessStatus: (
				<span style={{ color: getStatusColor(ProcessStatusFlag) }}>
					{ProcessStatusFlag}
				</span>
			),
			PaymentStatus: (
				<span style={{ color: getStatusColor(item.PaymentStatus) }}>
					{item.PaymentStatus}
				</span>
			),
			ModeOfFiling: <span>{ModeOfFilingFlag}</span>
		};
	});

	const headers = [
		["Bill Number", "BillNumber"],
		["Type of Assessment", "PayeType"],
		["Fiscal Year ", "fiscalYear"],
		["Duration", "PayTypeMode"],
		["Total Num of Staff", "totStaffNo"],
		["Total Monthly charge", "totMthAmtBilled"],
		["Creation Date", "InputterTime", formatDate],
		["Payment Status", "PaymentStatus"],
		["Process Status", "ProcessStatus"],
		["Reason for Cancellation", "RejectReason"]
	];

	return (
		<main className={styles.corporatePayeRecord}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<button
				className={`primary__btn ${anssidStyles.create__btn}`}
				onClick={() => history.push("/PAYE/PAYE-Assessment")}
			>
				PAYE Assessment
			</button>
			<div
				className={styles1.table__div}
				style={{ margin: "0px", marginTop: "32px" }}
			>
				<Table
					headers={headers}
					data={modifiedData}
					full
					pageSize={20}
					loading={loading}
					total={total}
					onPageChanged={getAssessmentRecords}
					onPay={(data) => {
						setBillNumber(data.BillNumber);
						setShowPaymentModal(true);
					}}
					onView={(data) => {
						history.push(`/Paye/Review`, {
							...data,
							ProcessStatus: processStatusMap[data.ApproveFlg],
							PaymentStatus: processStatusMap[data.PaymentStatus],
							ModeOfFiling:
								getModeOfFilingText[data.ModeOfFiling],
							view: true
						});
					}}
				/>
			</div>

			<section>
				{showPaymentModal && (
					<PaymentModal
						showing={showPaymentModal}
						toggle={setShowPaymentModal}
						billNumber={billNumber}
					/>
				)}
			</section>
		</main>
	);
};

export default CorporatePayeRecord;
