import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Mdas } from "./components/mdas";
import styles from "./style.module.css";
import EtransacLogo from "../assets/Home/etransacLogo.png";
import RegularModal from "../components/Modal/regular";
import constants from "../constants";
import { formatAmount, formatDate } from "../utils";
import backArrow from "../assets/arrowLeftWhite.svg";
import Alert from "../components/Alert";

axios.defaults.testURL = constants.BASE_URL;

const Etransacpayment = () => {
	const history = useHistory();
	const { state: locationState, search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);

	const [amount, setAmount] = useState(query.get("amount") || "");
	const [name, setName] = useState(query.get("name") || "");
	const [ESBN, setESBN] = useState(query.get("esbn") || "");
	const [fetchingESBNDetails, setFetchingESBNDetails] = useState(false);
	const [billNumber, setBillNumber] = useState(
		locationState?.billNumber || query.get("billNumber") || ""
	);
	const [fetchingBill, setFetchingBill] = useState(false);
	const [billInfo, setBillInfo] = useState(null);
	const [address, setAddress] = useState(query.get("address") || "");
	const [revenue, setRevenue] = useState(query.get("revcode") || "");
	const [revenues, setRevenues] = useState([]);
	const [mda, setMda] = useState(query.get("mdacode") || "");
	const [isCoreTax, setIsCoreTax] = useState(false);
	const [remark, setRemark] = useState("");
	const [paying, setPaying] = useState(false);
	const [consolidatedPaymentAlertOpen, setConsolidatedAlertOpen] =
		useState(false);
	const [payingWithBillNumber, setPayingWithBillNumber] = useState(
		query.get("method") === "2" ? false : true
	);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const publicKey = "1PUB1003LTbWjKNMe999P7dURzmNkFm5R3mssy";

	const startPayment = async () => {
		try {
			if (
				billInfo?.billDetails?.CentralisedBillDetails?.length > 1 &&
				!amount
			) {
				billInfo.billDetails?.CentralisedBillDetails.map(
					async (bill) => {
						await handlePost(bill.Amount, bill.RevenueCode);
					}
				);
			} else if (
				billInfo?.billDetails?.CentralisedBillDetails?.length > 1 &&
				amount
			) {
				billInfo.billDetails?.CentralisedBillDetails.map(
					async (bill) => {
						let amt = (bill.Amount / billInfo.Balance) * amount;

						await handlePost(amt, bill.RevenueCode);
					}
				);
			} else await handlePost();
		} catch (error) {
			if (error.response) {
				setPaying(false);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevenues(res);
	};

	useEffect(() => {
		RevenueHead();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mda]);

	useEffect(() => {
		const getBillAmount = async () => {
			try {
				setBillInfo(null);
				setFetchingBill(true);
				setRevenue("");
				setAmount("");
				let bill = (
					await axios.get(`/account/billv2/${billNumber}`, {
						headers: { secureddata: "VGhpc2lzTm9ybGljcw==" }
					})
				).data.data;

				setBillInfo(bill);
				setName(bill?.billDetails?.Name_on_Bill);
				setESBN(bill?.billDetails?.ESBN_PID_on_Bill);
				setAddress(
					bill?.billDetails?.CentralisedBillDetails[0].Address
				);

				if (bill?.billDetails?.CentralisedBillDetails?.length === 1) {
					const mdaCode =
						bill?.billDetails?.CentralisedBillDetails[0].RevenueCode.split(
							"/"
						)[0];

					setMda(mdaCode);
					setRevenue(
						bill?.billDetails?.CentralisedBillDetails[0].RevenueCode
					);
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response.data?.msg
					});
				}
			} finally {
				setFetchingBill(false);
			}
		};
		if (billNumber?.length >= 17) getBillAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billNumber]);

	function returnCallBackURL(revCode) {
		// if this page was opened from any bill payment module outside of the homepage
		if (locationState?.billNumber) {
			if (
				billInfo?.billDetails?.CentralisedBillDetails?.length > 1 &&
				billInfo?.billDetails?.CentralisedBillDetails[
					billInfo?.billDetails?.CentralisedBillDetails?.length - 1
				].RevenueCode === revCode
			)
				return locationState?.goBackTo
					? `${window.location.origin}/${locationState?.goBackTo}`
					: window.location.href;
			else if (
				billInfo?.billDetails?.CentralisedBillDetails?.length > 1 &&
				billInfo?.billDetails?.CentralisedBillDetails[
					billInfo?.billDetails?.CentralisedBillDetails?.length - 1
				].RevenueCode !== revCode
			)
				return window.location.href;
			else
				return locationState?.goBackTo
					? `${window.location.origin}/${locationState?.goBackTo}`
					: window.location.href;
		}
		// if this page was opened from another site other than ours
		else if (query.get("redirectURL")) return query.get("redirectURL");
		// if this page was opened from homepage
		else {
			return window.location.href;
		}
	}

	const handlePost = async (amt, revCode) => {
		setPaying(true);
		const postData = {
			callbackUrl: returnCallBackURL(revCode),
			amount: amt ? amt : amount * 100 || 100 * billInfo?.Balance,
			currency: "NGN",
			customerFirstName: ESBN ? ESBN : name,
			customerPhoneNumber: billInfo?.phoneNo,
			email: billInfo?.Email || "eirssupport@norlics.com.ng",
			metadata: {
				BillNumber: billNumber || "",
				Amount: amount,
				CustomerId: ESBN,
				payerPhoneNumber: billInfo?.PhoneNo || "",
				CustReference: name,
				Address: address,
				AgencyCode: revenue.split("/")[0],
				RevenueCode: revenue,
				Bill_from_which_Module:
					billInfo?.billDetails?.Bill_from_which_Module || ""
			}
		};

		try {
			const response = await axios.post(
				"https://api.credocentral.com/transaction/initialize",
				postData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: publicKey
						// Add other headers as needed
					}
				}
			);

			const transactionId = response?.data?.data?.credoReference;
			const redirectURL = `https://pay.credocentral.com/${transactionId}`;

			window.location.href = redirectURL;

			// Handle response as needed
		} catch (error) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response.data?.error.amount ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
			// Handle error as neededcredoReference
		}
	};

	const getESBNDetails = async () => {
		setFetchingESBNDetails(true);

		try {
			const res = await axios
				.get(`/users/esbnpid?anssid=${ESBN}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				if (!res?.firstname) {
					setName("");
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: "No data for this ESBN"
					});
				} else
					setName(
						`${res.firstname} ${res.middlename} ${res.surname}`
					);
				setAddress(res.residentialaddress || "");
			} else if (res.accounttype === "corporate") {
				setName(res.name);
				setAddress(res.stateOfficeAddress);
			} else {
				setName(res.PayerName);
				setAddress(res.PropertyAddr);
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetchingESBNDetails(false);
		}
	};

	async function checkCoreTaxes() {
		try {
			const res = await axios.get(
				`/txn/chkcoretax/${revenue.split("/").join("-")}`
			);

			if (res?.data?.revenueCode) setIsCoreTax(true);
			else setIsCoreTax(false);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	}

	useEffect(() => {
		if (!payingWithBillNumber) {
			setRevenue("");
			setESBN("");
			setName("");
			setAddress("");
			setAmount("");
			setRemark("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mda]);

	useEffect(() => {
		if (mda && !payingWithBillNumber) checkCoreTaxes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [revenue]);

	useEffect(() => {
		if (ESBN?.length >= 10 && !payingWithBillNumber) getESBNDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ESBN]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{consolidatedPaymentAlertOpen && (
				<RegularModal
					showing={consolidatedPaymentAlertOpen}
					toggle={setConsolidatedAlertOpen}
				>
					<p>
						You will go through the payment{" "}
						{billInfo?.billDetails?.CentralisedBillDetails?.length}{" "}
						times. Click okay to continue.{" "}
					</p>
					<button
						className="primary__btn"
						style={{
							display: "block",
							margin: "40px auto 0",
							padding: "0 25px"
						}}
						onClick={() => {
							setConsolidatedAlertOpen(false);
							startPayment();
						}}
					>
						Okay
					</button>
				</RegularModal>
			)}
			<main className={styles.main}>
				<div className={styles.Etransac__header}>
					<button
						className={styles.go__back}
						onClick={() => history.goBack()}
					>
						<img src={backArrow} alt="go back arrow" />
					</button>
					<img src={EtransacLogo} alt="etransac logo" />
				</div>
				<div className={styles.radio__btns}>
					<div>
						<input
							id="payWithBillNo"
							type="radio"
							value={true}
							checked={payingWithBillNumber}
							onChange={(e) => {
								setPayingWithBillNumber(true);
								setName("");
								setESBN("");
								setAmount("");
								setBillNumber("");
								setBillInfo(null);
								setAddress("");
								setRevenue("");
								setMda("");
								setRemark("");
								setIsCoreTax(false);
							}}
							disabled={
								query.get("method") === "2" ||
								query.get("billNumber")
							}
						/>
						<label htmlFor="payWithBillNo">
							Pay With Bill Number
						</label>
					</div>
					<div>
						<input
							id="payWithoutBillNo"
							type="radio"
							value={false}
							checked={!payingWithBillNumber}
							onChange={(e) => {
								setPayingWithBillNumber(false);
								setName("");
								setESBN("");
								setAmount("");
								setBillNumber("");
								setBillInfo(null);
								setAddress("");
								setRevenue("");
								setMda("");
								setRemark("");
								setIsCoreTax(false);
							}}
							disabled={
								query.get("method") === "2" ||
								query.get("billNumber")
							}
						/>
						<label htmlFor="payWithoutBillNo">
							Pay{" "}
							<b>
								<i>Without</i>
							</b>{" "}
							Bill Number
						</label>
					</div>
				</div>
				<div className={styles.wrapper}>
					{!payingWithBillNumber && (
						<div className={styles.payments__double__inputs}>
							<Mdas setValue={(val) => setMda(val)} value={mda} />
							<div>
								<label
									className={styles.input__label}
									htmlFor="mda"
								>
									Revenue{" "}
									{!mda && (
										<span
											style={{
												fontSize: "12.5px",
												color: "#7e7e7e"
											}}
										>
											(Pick MDA first)
										</span>
									)}{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="mda"
									className={styles.input__medium}
									name="mda"
									onChange={(e) => {
										setRevenue(e.target.value);
										if (!payingWithBillNumber) {
											setESBN("");
											setName("");
											setAddress("");
											setAmount("");
											setRemark("");
										}
									}}
									value={revenue}
									disabled={
										(payingWithBillNumber &&
											(!billNumber ||
												!mda ||
												!billInfo)) ||
										(!payingWithBillNumber && !mda) ||
										query.get("method") === "2"
									}
									required
								>
									<option value="">
										{revenues
											? "--Select Revenue head--"
											: "Loading..."}
									</option>
									{revenues &&
										revenues.map((getRev) => (
											<option
												key={getRev.Code}
												value={getRev.Code}
											>
												{getRev.Description}
											</option>
										))}
								</select>
							</div>
						</div>
					)}
					{payingWithBillNumber && (
						<div className={styles.single__input}>
							<label
								className={styles.input__label}
								htmlFor="BillNumber"
							>
								Bill Number{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								onChange={(e) => setBillNumber(e.target.value)}
								id="BillNumber"
								placeholder="Enter bill number"
								required
								value={billNumber}
								disabled={
									query.get("billNumber") ||
									locationState?.billNumber
								}
							/>
						</div>
					)}
					<div className={styles.payments__double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="ESBN"
							>
								ESBN/PID{" "}
								{(payingWithBillNumber || isCoreTax) && (
									<span style={{ color: "red" }}>*</span>
								)}{" "}
								{!payingWithBillNumber &&
									(!mda || !revenue) && (
										<span
											style={{
												fontSize: "12.5px",
												color: "#7e7e7e"
											}}
										>
											(Pick MDA & Revenue first)
										</span>
									)}
								{ESBN && ESBN.length < 10 && (
									<span
										style={{
											fontSize: "12.5px",
											color: "red"
										}}
									>
										(Must be, at least, 10 characters long)
									</span>
								)}
							</label>
							<input
								type="text"
								id="ESBN"
								placeholder="Enter your ESBN or PID"
								required={payingWithBillNumber}
								value={ESBN}
								onChange={(e) => {
									setESBN(e.target.value);
									if (!payingWithBillNumber) {
										setName("");
										setAddress("");
									}
								}}
								disabled={
									payingWithBillNumber ||
									query.get("method") === "2" ||
									(!payingWithBillNumber &&
										(!mda || !revenue))
								}
							/>
							{fetchingESBNDetails && (
								<p
									style={{
										fontSize: "12px",
										lineHeight: "0",
										margin: "10px 0 0",
										color: "#9e9e9e"
									}}
								>
									fetching details...
								</p>
							)}
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="name"
							>
								Name <span style={{ color: "red" }}>*</span>{" "}
								{isCoreTax && !ESBN && (
									<span
										style={{
											fontSize: "12.5px",
											color: "#7e7e7e"
										}}
									>
										(Enter ESBN/PID first)
									</span>
								)}
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								id="name"
								placeholder="Enter Name"
								required
								disabled={
									payingWithBillNumber ||
									query.get("method") === "2" ||
									(!payingWithBillNumber &&
										(!mda || !revenue)) ||
									isCoreTax
								}
							/>
						</div>
					</div>
					<div className={styles.single__input}>
						<label
							className={styles.input__label}
							htmlFor="address"
						>
							Address <span style={{ color: "red" }}>*</span>{" "}
							{isCoreTax && !ESBN && (
								<span
									style={{
										fontSize: "12.5px",
										color: "#7e7e7e"
									}}
								>
									(Enter ESBN/PID first)
								</span>
							)}
						</label>
						<input
							id="address"
							placeholder="Enter address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							disabled={
								payingWithBillNumber ||
								query.get("method") === "2" ||
								(!payingWithBillNumber && (!mda || !revenue)) ||
								isCoreTax
							}
							required
						/>
					</div>
					{/* Revenue Head Bill Breakdown */}
					{fetchingBill && (
						<p style={{ textAlign: "center" }}>Loading...</p>
					)}
					{billInfo && (
						<table className={styles.table}>
							<thead>
								<tr>
									<th>S/N</th>
									<th>CATEGORY (REVENUE HEAD)</th>
									<th>Outstanding Bill (₦)</th>
									<th>Current Bill (₦)</th>
								</tr>
							</thead>
							<tbody>
								{billInfo?.billDetails?.CentralisedBillDetails.map(
									(each, i) => (
										<tr key={each.MDA}>
											<td>{i + 1}.</td>
											<td>{each.BillDescription}</td>
											<td>{each.OutstandingAmount}</td>
											<td>
												₦{formatAmount(each.Amount)}
											</td>
										</tr>
									)
								)}
								<tr className={styles.no__border_bottom}>
									<td></td>
									<td></td>
									<td className={styles.discount}>
										DISCOUNT
									</td>
									<td className={styles.discount}>
										₦
										{formatAmount(
											billInfo?.billDetails
												?.Total_Discount_Bill_Amount
										)}
									</td>
								</tr>
								<tr className={styles.no__border_bottom}>
									<td></td>
									<td></td>
									<td className={styles.discount}>
										Total Outstanding
									</td>
									<td className={styles.discount}>
										₦
										{formatAmount(
											billInfo?.billDetails
												?.Total_Outstanding_Bill_Amount
										)}
									</td>
								</tr>
								<tr className={styles.no__border_bottom}>
									<td></td>
									<td></td>
									<td className={styles.total}>TOTAL DUE</td>
									<td className={styles.total}>
										₦
										{formatAmount(
											billInfo?.billDetails
												?.Total_Grand_Bill_Amount
										)}
									</td>
								</tr>
							</tbody>
						</table>
					)}
					{Boolean(billInfo?.paymentDetails.length) && (
						<>
							<h3 className={styles.payment__history_header}>
								Bill Payment History
							</h3>
							<table className={styles.table}>
								<thead>
									<tr>
										<th>S/N</th>
										<th style={{ visibility: "hidden" }}>
											Payment Date
										</th>
										<th>Payment Date</th>
										<th>Amount Paid</th>
									</tr>
								</thead>
								<tbody>
									{billInfo?.paymentDetails.map((each, i) => (
										<tr key={each.PaymentDate}>
											<td>{i + 1}.</td>
											<td></td>
											<td>
												{formatDate(each.PaymentDate)}
											</td>
											<td>
												₦{formatAmount(each.Amount)}
											</td>
										</tr>
									))}
									<tr className={styles.no__border_bottom}>
										<td></td>
										<td></td>
										<td className={styles.discount}>
											Total Paid
										</td>
										<td className={styles.discount}>
											₦
											{formatAmount(billInfo?.totPayment)}
										</td>
									</tr>
									<tr className={styles.no__border_bottom}>
										<td></td>
										<td></td>
										<td className={styles.total}>
											Balance
										</td>
										<td className={styles.total}>
											₦{formatAmount(billInfo?.Balance)}
										</td>
									</tr>
								</tbody>
							</table>
						</>
					)}
					{billInfo?.billDetails?.CentralisedBillDetails?.length ===
						1 && (
						<div className={styles.payments__double__inputs}>
							<Mdas setValue={(val) => setMda(val)} value={mda} />
							<div>
								<label
									className={styles.input__label}
									htmlFor="mda"
								>
									Revenue{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="mda"
									className={styles.input__medium}
									name="mda"
									onChange={(e) => {
										setRevenue(e.target.value);
									}}
									value={revenue}
									disabled={
										(payingWithBillNumber &&
											(!billNumber ||
												!mda ||
												!billInfo)) ||
										(!payingWithBillNumber && !mda) ||
										query.get("method") === "2"
									}
									required
								>
									<option value="">
										{revenues
											? "--Select Revenue head--"
											: "Loading..."}
									</option>
									{revenues &&
										revenues.map((getRev) => (
											<option
												key={getRev.Code}
												value={getRev.Code}
											>
												{getRev.Description}
											</option>
										))}
								</select>
							</div>
						</div>
					)}
					{!payingWithBillNumber && (
						<div className={styles.single__input}>
							<label
								className={styles.input__label}
								htmlFor="remark"
							>
								Remark
							</label>
							<textarea
								id="remark"
								placeholder="Enter remark"
								value={remark}
								onChange={(e) => setRemark(e.target.value)}
								rows={3}
								disabled={payingWithBillNumber}
							/>
						</div>
					)}
					{locationState?.referrer !== "/coupon/purchase" && (
						<div className={styles.single__input}>
							<label
								className={styles.input__label}
								htmlFor="amount"
							>
								Amount to Pay (
								{billInfo?.billDetails?.CentralisedBillDetails
									?.length > 1 && "Min. Amount: ₦ 2000."}{" "}
								Ignore and Click “Pay Now” to pay in full){" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="number"
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
								id="amount"
								placeholder="Enter amount"
								required
							/>
							{billInfo?.billDetails?.CentralisedBillDetails
								?.length > 1 &&
								amount &&
								amount < 2000 && (
									<span style={{ color: "red" }}>
										Amount must be, at least, ₦ 2000
									</span>
								)}
						</div>
					)}
					<button
						onClick={() => {
							if (
								billInfo?.billDetails?.CentralisedBillDetails
									?.length > 1
							)
								setConsolidatedAlertOpen(true);
							else startPayment();
						}}
						className={["primary__btn", styles.btn].join(" ")}
						disabled={
							(payingWithBillNumber &&
								(!billNumber ||
									!billInfo ||
									(billInfo?.billDetails
										?.CentralisedBillDetails?.length ===
										1 &&
										(!revenue || !mda)))) ||
							(!payingWithBillNumber &&
								((ESBN && ESBN.length < 10) ||
									!name ||
									!address ||
									!mda ||
									!revenue ||
									!amount)) ||
							(isCoreTax && ESBN.length < 10) ||
							(amount &&
								billInfo?.billDetails?.CentralisedBillDetails
									?.length > 1 &&
								+amount < 50)
						}
					>
						{paying
							? `Paying ₦ 
						${formatAmount(amount || billInfo?.Balance)}
						... `
							: `Pay ₦ 
						${formatAmount(amount || billInfo?.Balance)}
						 now`}
					</button>
				</div>
			</main>
		</div>
	);
};

export default Etransacpayment;
